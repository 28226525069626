<script setup lang="ts">
const emits = defineEmits(["update:modelValue"]);

defineProps({
  modelValue: {
    type: String,
    default: ""
  },
  values: {
    type: Array,
    default: [] as string[]
  }
});
</script>

<template>
  <div class="bg-gray-200 h-fit w-fit rounded-md flex gap-1 p-1">
    <template v-for="value in values" :key="value">
      <p
        class="px-2.5 py-1 text-lc-gray-arsenic leading-6 rounded-md cursor-pointer transition-colors duration-100 ease-out"
        :class="modelValue === value ? 'bg-white' : 'bg-none'"
        @click="emits('update:modelValue', value)"
      >
        {{ value }}
      </p>
    </template>
  </div>
</template>
