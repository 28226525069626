<script setup lang="ts">
import { onMounted, PropType } from "vue";

const emits = defineEmits(["update:modelValue", "deleteError"]);

const props = defineProps({
  modelValue: {
    type: Number as PropType<number | string>,
    required: true
  },
  placeholder: {
    type: String,
    default: ""
  },
  name: {
    type: String
  },
  label: {
    type: String,
    default: ""
  },
  labelPosition: {
    type: String as PropType<"top" | "left">,
    default: "top"
  },
  selected: {
    type: Number as PropType<number | string | undefined>,
    default: undefined
  },
  data: {
    type: Array || Object
  },
  settings: {
    type: Object as PropType<Record<string, string>>
  },
  type: {
    type: String,
    default: "white"
  },
  size: {
    type: String,
    default: "base"
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  loadingMessage: {
    type: String,
    default: "Chargement en cours ..."
  },
  selectClass: {
    type: String,
    default: ""
  },
  error: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

onMounted(() => {
  if (props.selected) emits("update:modelValue", props.selected);
});

function getNestedData(row: any, requestColName: string | string[] | undefined): string | string[] {
  if (!requestColName) return row;

  let requestTable: string[];
  const resultTable: string[] = [];

  if (typeof requestColName === "string") {
    requestTable = [requestColName];
  } else {
    requestTable = requestColName;
  }

  requestTable.forEach((element) => {
    let temp: any = row;
    const array = element.toString().split(".");
    for (const element of array) {
      temp = temp?.[element];
    }
    resultTable.push(`${temp}`);
  });

  return resultTable.join(" ");
}

function change(event: Event) {
  emits("update:modelValue", (event.target as HTMLSelectElement).value);
  if (props.error) emits("deleteError");
}

function getType() {
  if (props.type === "transparent") return "bg-none";
  else return "bg-white";
}

function getSize() {
  let style: string;
  switch (props.size) {
    case "sm":
      style = "py-[6px]";
      break;
    case "lg":
      style = "py-[12px]";
      break;
    default:
      style = "py-[9px]";
      break;
  }
  return style;
}

function selectedOption(one: any, settings: any) {
  return props.selected != undefined
    ? props.selected.toString() === getNestedData(one, settings?.value)
    : false;
}

function getPositionLabel() {
  if (props.labelPosition === "top") return "flex-col justify-between";
  else return "flex-row";
}
</script>

<template>
  <div id="box" class="flex" :class="[getPositionLabel(), { 'gap-1': label }]">
    <label :for="name" class="font-medium text-sm text-lc-charcoal">
      {{ label }}
    </label>
    <div class="flex items-center" :class="selectClass">
      <select
        :id="name"
        class="border rounded w-full h-[46px] px-[5px] sm:px-[13px] text-base outline-none"
        :class="[
          getType(),
          getSize(),
          error ? 'text-red-600 border-red-300' : 'border-gray-300  text-lc-gray-blackCoral'
        ]"
        @change="change"
      >
        <option v-if="isLoading" :selected="true" disabled class="text-center text-lc-gray-arsenic">
          {{ loadingMessage }}
        </option>

        <option v-if="placeholder && !selected" :selected="true" disabled>
          {{ placeholder }}
        </option>
        <option
          v-for="one in data"
          :value="getNestedData(one, settings?.value)"
          :key="getNestedData(one, settings?.value)[0]"
          :selected="selectedOption(one, settings)"
          :disabled="disabled"
        >
          {{ getNestedData(one, settings?.display) }}
        </option>
      </select>
    </div>
    <div
      v-if="error"
      class="inline-flex text-ellipsis text-sm leading-5 font-medium text-red-600 w-full"
    >
      {{ error }}
    </div>
  </div>
</template>
