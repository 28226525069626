<script setup lang="ts">
import { computed } from "vue";
import starGray from "@/assets/icons/iconsax/bold/star-2.svg";
import starYellow from "@/assets/icons/iconsax/bold/star-2-yellow.svg";

const props = defineProps({
  icon: {
    type: String,
    default: "star-2"
  },
  isSelected: Boolean,
  isHover: Boolean,
  customClass: {
    type: String,
    default: ""
  }
});

const iconSource = computed(() => {
  return props.isSelected || props.isHover ? starYellow : starGray;
});
</script>

<template>
  <img :src="iconSource" :class="[customClass, 'w-10 cursor-pointer px-1']" alt="star" />
</template>
