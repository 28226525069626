<script setup lang="ts">
import { type PropType } from "vue";
import { TransitionRoot } from "@headlessui/vue";

const emits = defineEmits(["close"]);

const props = defineProps({
  isShow: {
    type: Boolean,
    default: false
  },
  parameters: {
    type: String as PropType<"description" | "list">,
    default: "description"
  },
  type: {
    type: String as PropType<"danger" | "info" | "success" | "warning">,
    default: "danger"
  },
  title: {
    type: String,
    default: ""
  },
  description: {
    type: String
  },
  list: {
    type: Array as PropType<string[]>
  },
  align: {
    type: Boolean,
    default: false
  }
});

function getTitleStyleByType() {
  let style: string;
  switch (props.type) {
    case "danger":
      style = "text-red-800";
      break;
    case "info":
      style = "text-blue-800";
      break;
    case "success":
      style = "text-green-800";
      break;
    case "warning":
      style = "text-yellow-800";
      break;
    default:
      style = "";
      break;
  }
  return style;
}

function getDescriptionStyleByType() {
  let style: string;
  switch (props.type) {
    case "danger":
      style = "text-red-700";
      break;
    case "info":
      style = "text-blue-700";
      break;
    case "success":
      style = "text-green-700";
      break;
    case "warning":
      style = "text-yellow-700";
      break;
    default:
      style = "";
      break;
  }
  return style;
}

function getBackgroundStyleByType() {
  let style: string;
  switch (props.type) {
    case "danger":
      style = "bg-red-50";
      break;
    case "info":
      style = "bg-blue-50";
      break;
    case "success":
      style = "bg-green-50";
      break;
    case "warning":
      style = "bg-yellow-50";
      break;
    default:
      style = "";
      break;
  }
  return style;
}

function getSvgLinkByType(): string {
  let icon: string;
  switch (props.type) {
    case "danger":
      icon = "close-circle-red-400";
      break;
    case "info":
      icon = "info-circle-blue-400";
      break;
    case "success":
      icon = "tick-circle-green-400";
      break;
    case "warning":
      icon = "danger-yellow-400";
      break;
    default:
      icon = "";
      break;
  }
  return new URL(`/src/assets/icons/iconsax/bold/${icon}.svg`, import.meta.url).href;
}
</script>

<template>
  <TransitionRoot
    :show="isShow"
    appear
    as="template"
    enter="transform transition duration-[400ms] ease-out"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transform transition duration-[100ms] ease-in"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <div class="rounded-md p-4" :class="getBackgroundStyleByType()" @click="emits('close')">
      <div class="flex">
        <div class="flex-shrink-0">
          <img
            :src="getSvgLinkByType()"
            :class="['h-5 w-5', { 'rotate-180': type === 'info' }]"
            aria-hidden="true"
            alt=""
          />
        </div>
        <div :class="['ml-3 flex gap-2', { 'flex-col': !align }]">
          <h3 v-if="title" :class="['text-sm font-semibold', getTitleStyleByType()]">
            {{ title }}
          </h3>

          <div v-if="list || description" :class="['text-sm', getDescriptionStyleByType()]">
            <p v-if="parameters === 'description'">
              {{ description }}
            </p>
            <ul v-else-if="parameters === 'list'" class="list-disc">
              <li v-for="element in list" :key="element">{{ element }}</li>
            </ul>
          </div>

          <div class="text-sm text-blue-700">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>
