<script setup lang="ts">
const emits = defineEmits(["update:modelValue"]);

defineProps({
  name: {
    type: String,
    default: ""
  },
  label: {
    type: String,
    default: ""
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  before: {
    type: String,
    default: ""
  },
  after: {
    type: String,
    default: ""
  },
  inline: {
    type: Boolean,
    default: false
  },
  reverse: {
    type: Boolean,
    default: true
  }
});
</script>

<template>
  <div
    :class="[
      'w-fit flex gap-2 h-fit',
      inline ? (reverse ? 'flex-row-reverse items-center' : 'flex-row items-center') : 'flex-col'
    ]"
  >
    <label v-if="label" :for="name" class="text-sm leading-5 font-medium text-lc-charcoal">
      {{ label }}
    </label>
    <div class="flex flex-row gap-2 items-center mr-1">
      <p v-if="before">{{ before }}</p>
      <label class="cursor-pointer">
        <input
          @change="emits('update:modelValue', ($event?.target as HTMLInputElement)?.checked)"
          :checked="modelValue"
          type="checkbox"
          :id="name"
          hidden
        />
        <span
          class="w-11 h-6 flex items-center rounded-full p-0.5 duration-300 shadow-sm"
          :class="modelValue ? 'bg-lc-primary' : 'bg-gray-300'"
        >
          <span
            class="bg-white w-5 h-5 rounded-full shadow-sm transform duration-300"
            :class="{ 'translate-x-5': modelValue }"
          ></span>
        </span>
      </label>
      <p v-if="after">{{ after }}</p>
    </div>
  </div>
</template>
