<script setup lang="ts">
import { PropType } from "vue";
import type {
  And,
  Button,
  Checkbox,
  Condition,
  ConditionnalButton,
  ConditionnalIcon,
  EmptyTable,
  Icon,
  LoadData,
  NoData,
  Or,
  Pill,
  RowSetting,
  Setting,
  Toggle
} from "@/interfaces/components/PeTable.interface";
import nothingFound from "@/assets/lottiefiles/nothing-found.json";
import { formatOgustDay, formatOgustHour } from "@/utils/formatOgustData";
import { format } from "date-fns";
import { fr } from "date-fns/locale/fr";

const emits = defineEmits(["getEmit", "noDataClick", "toggleValue", "load"]);

const props = defineProps({
  isLoading: { type: Boolean, default: false },
  settings: { type: Array as PropType<Setting[]>, default: () => [] },
  rowSettings: { type: Object as PropType<RowSetting>, default: undefined },
  dataTable: { type: Array as PropType<any[]>, default: () => [] },
  emptyTable: {
    type: Object as PropType<EmptyTable>,
    default: () => ({ isEmpty: false, message: "" })
  },
  noData: {
    type: Object as PropType<NoData>,
    default: () => ({
      title: "Cette table est vide.",
      description: "Cliquer en dessous pour effectuer une action.",
      button: { label: "Ajouter quelque chose" }
    })
  },
  loadMore: { type: Object as PropType<LoadData> },
  defaultLoad: { type: Number, default: 999 },
  skeletonSize: { type: Number, default: 5 }
});

function buildData(reqColName: string | string[], data: any, format?: string) {
  try {
    if (!Array.isArray(reqColName)) reqColName = [reqColName];

    let temp = [];
    for (const col of reqColName) {
      // Check si on a un tableau
      if (col.includes("[") && col.includes("]")) {
        let formData = data;
        const res = extractArray(col);

        for (const r of res) {
          if (r.includes("[") && r.includes("]")) {
            const array = r.split("[");
            formData = formData[array[0]];
            const arrayContent = array[1].replace("]", "");

            const content = extractArrayContent(arrayContent, data);
            formData = formData[content];
            continue;
          }

          formData = formData[r];
        }

        temp.push(formData);
        continue;
      }

      // Build la data
      let formData = data;
      const getData = col.split(".");

      for (const item of getData) {
        formData = formData[item];
      }

      temp.push(formData);
    }

    if (temp.length === 1) data = temp[0];
    else if (temp.length > 1 && !format) data = temp.join(" ");
    else data = temp;

    return format ? dataFormatting(data, format) : data;
  } catch (e) {
    return "";
  }
}

function extractArray(arr: string) {
  // Sépare les données lorsqu'on a un tableau
  // ex: data.array[data.data].data
  // return [data, array[data.data], data]
  const res: string[] = [];
  let currentToken = "";
  let bracketCount = 0;

  for (const item of arr) {
    const char = item;

    if (char === "." && bracketCount === 0) {
      res.push(currentToken);
      currentToken = "";
    } else {
      currentToken += char;
      if (char === "[") bracketCount++;
      else if (char === "]") bracketCount--;
    }
  }

  if (currentToken !== "") res.push(currentToken);
  return res;
}

function extractArrayContent(content: string, data: any) {
  let res: string;
  const add = content.split("+");
  const subtract = content.split("-");

  if (add.length > 1) res = add[0];
  else if (subtract.length > 1) res = subtract[0];
  else res = content;

  let formData = data;
  const getData = res.split(".");

  for (const item of getData) {
    formData = formData[item.trim()];
  }

  if (add.length > 1) formData = formData + parseInt(add[1]);
  else if (subtract.length > 1) formData = formData - parseInt(subtract[1]);
  return formData;
}

function buildButton(
  button: Button | undefined,
  buttons: ConditionnalButton | undefined,
  data: any
): Button | undefined {
  // Si on a un bouton, on le retourne directement
  if (button) return button;
  if (!buttons) return;

  //  Check de la condition des boutons
  const condition = checkCondition(buttons.condition, data);

  //  On définit les valeurs à retourner
  const parameter =
    buttons.parameter.length === 1 ? buttons.parameter[0] : buttons.parameter[condition ? 0 : 1];

  const name = buttons.name
    ? buttons.name.length === 1
      ? buttons.name[0]
      : buttons.name[condition ? 0 : 1]
    : undefined;

  const icon = buttons.icon
    ? buttons.icon.length === 1
      ? buttons.icon[0]
      : buttons.icon[condition ? 0 : 1]
    : undefined;

  const animation = buttons.animation
    ? buttons.animation.length === 1
      ? buttons.animation[0]
      : buttons.animation[condition ? 0 : 1]
    : undefined;

  const class_ = buttons.class_
    ? buttons.class_.length === 1
      ? buttons.class_[0]
      : buttons.class_[condition ? 0 : 1]
    : undefined;

  // Retourne le bouton
  return { parameter, name, icon, animation, class_ } satisfies Button;
}

function buildPill(pill: Pill | undefined, data: any): Pill | undefined {
  if (!pill) return;
  return { label: buildData(pill.label, data), type: buildData(pill.type, data) } satisfies Pill;
}

function buildIcon(
  icon: Icon | undefined,
  icons: ConditionnalIcon | undefined,
  data: any
): Icon | undefined {
  // Si on a un icon, on le retourne directement
  if (icon) return icon;
  if (!icons) return;

  // Check la condition des icons
  const condition = checkCondition(icons.condition, data);

  // On défini les valeurs à retourner
  const name = icons.name.length === 1 ? icons.name[0] : icons.name[condition ? 0 : 1];
  const type = icons.type.length === 1 ? icons.type[0] : icons.type[condition ? 0 : 1];

  const size = icons.size
    ? icons.size.length === 1
      ? icons.size[0]
      : icons.size[condition ? 0 : 1]
    : undefined;

  const class_ = icons.class_
    ? icons.class_.length === 1
      ? icons.class_[0]
      : icons.class_[condition ? 0 : 1]
    : undefined;

  // Retourne l'icon
  return { name, type, size, class_ } satisfies Icon;
}

function buildToggle(toggle: Toggle | undefined, data: any): Toggle | undefined {
  if (!toggle) return;
  return {
    value: buildData(toggle.value, data),
    before: toggle.before,
    after: toggle.after
  } satisfies Toggle;
}

function buildCheckbox(checkbox: Checkbox | undefined, data: any): Checkbox | undefined {
  if (!checkbox) return;
  return {
    value: buildData(checkbox.value, data),
    disabled: checkbox.disabled,
    class_: checkbox.class_
  } satisfies Checkbox;
}

function dataFormatting(data: string | string[], formatRequested: string): string {
  let temp: string;
  switch (formatRequested) {
    case "ogustDay":
      temp = formatOgustDay(+data);
      break;
    case "ogustHour":
      temp = formatOgustHour(data[0], data[1], "à");
      break;
    case "day":
      if (typeof data !== "string") return "";
      data = new Date(data).toDateString();
      temp = format(new Date(data), "EEEE", { locale: fr });
      break;
    case "hh:mm":
      if (typeof data !== "string") return "";
      data = new Date(data).toDateString();
      temp = format(new Date(data), "HH:mm");
      break;
    case "date":
      if (typeof data !== "string") return "";
      data = new Date(data).toDateString();
      temp = format(new Date(data), "dd/MM/yyyy");
      break;
    case "datetime":
      if (typeof data !== "string") return "";
      data = new Date(data).toDateString();
      temp = format(new Date(data), "dd/MM/yyyy HH:mm");
      break;
    default:
      temp = "";
      break;
  }
  return temp;
}

function getRowSettings(data: any) {
  if (props.rowSettings?.condition) {
    const condition = checkCondition(props.rowSettings.condition, data);
    if (!condition) return "";
    if (props.rowSettings?.class_) return props.rowSettings.class_;
  }

  if (props.rowSettings?.multipleCondition) {
    const condition = checkMultipleCondition(props.rowSettings.multipleCondition, data);
    if (!condition) return "";
    if (props.rowSettings?.class_) return props.rowSettings.class_;
  }

  if (props.rowSettings?.conditionnalClass) {
    const condition = checkCondition(props.rowSettings.conditionnalClass.condition, data);
    if (!condition) return props.rowSettings.conditionnalClass.class_[1];
    return props.rowSettings.conditionnalClass.class_[0];
  }
}

function checkCondition(condition: Condition | undefined, data: any): boolean {
  if (!condition) return true;

  const check = condition.compare.where;
  const reqColName = buildData(condition.value, data);
  return check(reqColName);
}

function checkMultipleCondition(conditions: And | Or | undefined, data: any): boolean {
  if (!conditions) return true;

  const res = [];

  for (const condition of conditions.cdts()) {
    const check = condition.compare.where;
    const reqColName = buildData(condition.value, data);
    res.push(check(reqColName));
  }

  return conditions.where(res);
}

function getBreakpoint(breakpoint: string | undefined): string {
  switch (breakpoint) {
    case "sm":
      return "hidden sm:table-cell";
    case "md":
      return "hidden md:table-cell";
    case "lg":
      return "hidden lg:table-cell";
    case "xl":
      return "hidden xl:table-cell";
    case "2xl":
      return "hidden 2xl:table-cell";
    default:
      return "";
  }
}
</script>

<template>
  <div class="flex flex-col rounded-lg shadow-sm drop-shadow overflow-hidden">
    <div class="overflow-x-auto bg-white">
      <table
        v-if="dataTable.length || isLoading"
        class="daisy-table daisy-table-sm daisy-table-pin-rows"
      >
        <!-- ===== Head ===== -->
        <thead>
          <tr class="bg-neutral-100 cursor-default">
            <th
              v-for="setting of settings"
              :key="setting.name"
              :class="[getBreakpoint(setting?.breakpoint), 'leading-5']"
            >
              {{ isLoading ? "" : setting.name }}
            </th>
          </tr>
        </thead>
        <!-- ================ -->

        <!-- ===== Loading ===== -->
        <tbody v-if="isLoading">
          <tr v-for="item in skeletonSize" :key="item" class="h-[1.5rem] bg-white">
            <td v-for="setting in settings" :key="setting.name" class="px-[.4rem] py-[10.8px]">
              <span class="table-cell w-screen h-[1.2rem] bg-gray-200 animate-pulse"></span>
            </td>
          </tr>
        </tbody>
        <!-- =================== -->

        <!-- ===== Body ===== -->
        <tbody v-else>
          <tr
            v-for="data of dataTable"
            :key="data"
            :class="['lg:hover:bg-neutral-100', getRowSettings(data)]"
          >
            <pe-table-td
              v-for="setting of settings"
              :key="setting.name"
              :data="buildData(setting.requestColName, data, setting.format)"
              :button="buildButton(setting.button, setting.conditionnalButton, data)"
              :pill="buildPill(setting.pill, data)"
              :icon="buildIcon(setting.icon, setting.conditionnalIcon, data)"
              :toggle="buildToggle(setting.toggle, data)"
              :checkbox="buildCheckbox(setting.checkbox, data)"
              :link="setting.link"
              :color="setting.color"
              :condition="checkCondition(setting.condition, data)"
              :multipleCondition="checkMultipleCondition(setting.multipleCondition, data)"
              :breakpoint="getBreakpoint(setting?.breakpoint)"
              :class_="setting.class_"
              @getClick="(data: any, parameter: string) => emits('getEmit', data, parameter)"
              @toggleValue="(data: any, value: boolean) => emits('toggleValue', data, value)"
            />
          </tr>
        </tbody>
        <!-- ================ -->
      </table>

      <!-- Empty Table -->
      <table
        v-else-if="emptyTable.isEmpty"
        class="table table-sm table-pin-rows w-full rounded-none"
      >
        <thead class="bg-lc-lotion">
          <tr class="bg-neutral-100 cursor-default">
            <th scope="col" class="leading-5">
              <span class="opacity-0">Vide</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="h-[1.5rem] bg-white">
            <td class="text-gray-500 text-center py-2 px-8">
              <span>{{ emptyTable.message }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- NoData -->
      <div v-else>
        <div class="my-8 w-full sm:w-10/12 md:w-2/3 text-center mx-auto">
          <div class="mx-auto w-40 mb-4">
            <Vue3Lottie :animationData="nothingFound" />
          </div>
          <p class="font-semibold text-xl leading-8 text-lc-charcoal">{{ noData?.title }}</p>
          <p class="sm:w-4/5 mx-auto mt-2 mb-5 text-lc-charcoal">{{ noData?.description }}</p>
          <pe-btn
            v-if="noData.button"
            type="primary"
            size="base"
            :label="noData.button.label"
            :icon="noData.button.icon"
            :isLoading="isLoading"
            class="mx-auto"
            @click="emits('noDataClick')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
