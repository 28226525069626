import { defineStore } from "pinia";
import { Company } from "@/interfaces/company.interface";
import { Account, MultiUser } from "@/interfaces/user.interface";
import { IndexedDatabase } from "@/utils/indexedDatabase";
import { getAllMultiUser } from "@/services/users";
import { DecodedJwtToken } from "@/interfaces/jwtToken.interface";
import { getDecodedToken } from "@/utils/tokenUtils";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: {
      id: undefined,
      lastname: undefined,
      email: undefined,
      firstname: undefined,
      role: undefined,
      birthDate: undefined,
      company: undefined,
      passwordReset: undefined,
      firstLogin: undefined,
      image: undefined,
      about: undefined,
      isEmailConfirmed: undefined,
      isManager: undefined,
      idOgust: undefined,
      isLocked: undefined,
      dechargePath: undefined,
      isDechargeSigned: undefined,
      needRating: undefined,
      sessions: undefined,
      needRenewal: undefined,
      mcStatus: undefined,
      isMixte: undefined
    },
    multiAccount: {
      state: undefined,
      users: []
    } as Account
  }),
  getters: {
    getUser(state: any) {
      return state.user;
    },
    getId(state: any): number {
      return state.user.id;
    },
    getFullname(state: any): string {
      return `${state.user.firstname} ${state.user.lastname}`;
    },
    getLastname(state: any): string {
      return state.user.lastname;
    },
    getFirstname(state: any): string {
      return state.user.firstname;
    },
    getFirstLogin(state: any): boolean {
      return state.user.firstLogin;
    },
    getEmail(state: any): string {
      return state.user.email;
    },
    getRole(state: any): string {
      return state.user.role;
    },
    getBirthDate(state: any): string {
      return state.user.birthDate;
    },
    getCompany(state: any): Company | undefined {
      return state.user.company;
    },
    getCompanyId(state: any): number | undefined {
      return state.user?.company?.id;
    },
    getCompanyNeedPayments(state: any): boolean | undefined {
      return state.user?.company?.needPayments;
    },
    getEmailConfirmation(state: any): boolean {
      return state.user.isEmailConfirmed;
    },
    getIsManager(state: any): boolean {
      return state.user.isManager;
    },
    getIdOgust(state: any): number {
      return state.user.idOgust;
    },
    getIsLocked(state: any): number {
      return state.user.isLocked;
    },
    getDechargePath(state: any): string {
      return state.user.dechargePath;
    },
    getIsDechargeSigned(state: any): boolean {
      return state.user.isDechargeSigned;
    },
    getNeedRating(state: any): boolean {
      return state.user.needRating;
    },
    getNeedRenewal(state: any): boolean {
      return state.user.needRenewal;
    },
    getMcStatus(state: any): number {
      return state.user.mcStatus;
    },
    getIsMixte(state: any): boolean {
      return state.user.isMixte;
    },
    isMobile(): boolean {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    // IndexedDB & MultiAccount
    getState(state: any): string | undefined {
      return state.multiAccount.state;
    },
    getUsers(state: any): MultiUser[] {
      return state.multiAccount.users;
    }
  },
  actions: {
    login(user: any): void {
      const role: string = setRole(user?.role);
      this.user = {
        id: user?.id,
        lastname: user?.lastname,
        firstname: user?.firstname,
        email: user?.email,
        role: role,
        birthDate: user?.birthDate,
        company: user?.company,
        passwordReset: user?.passwordReset,
        firstLogin: user?.firstLogin,
        image: user?.image,
        about: user?.about,
        isEmailConfirmed: user?.isEmailConfirmed,
        isManager: user?.isManager && role === "participant",
        idOgust: user?.idOgust,
        isLocked: user?.isLocked,
        dechargePath: user?.dechargePath,
        isDechargeSigned: user?.isDechargeSigned,
        needRating: user?.needRating,
        needRenewal: user?.needRenewal,
        mcStatus: user?.mcStatus,
        isMixte: user?.role === "mixte"
      };
    },
    refresh(user: any): void {
      this.login(user);
    },
    logout(): void {
      this.user = {
        id: undefined,
        lastname: undefined,
        firstname: undefined,
        email: undefined,
        role: undefined,
        birthDate: undefined,
        company: undefined,
        passwordReset: undefined,
        firstLogin: undefined,
        image: undefined,
        about: undefined,
        isEmailConfirmed: undefined,
        isManager: undefined,
        idOgust: undefined,
        isLocked: undefined,
        dechargePath: undefined,
        isDechargeSigned: undefined,
        needRating: undefined,
        mcStatus: undefined,
        needRenewal: undefined,
        isMixte: undefined
      };
    },
    // IndexedDB & MultiAccount
    setState(state: "loading" | "success" | "error" | "none"): void {
      this.multiAccount.state = state;
    },
    async setUsers(database: IndexedDatabase | undefined): Promise<void> {
      try {
        this.multiAccount.users = [];

        if (!database) {
          this.setState("none");
          return;
        }
        this.setState("loading");

        // Récupère les comptes de l'IndexedDB
        let dbAccounts = await database.getAccounts();
        if (dbAccounts.length === 0) {
          this.setState("success");
          return;
        }

        // Si l'utilisateur est connecté, supprime son compte de l'IndexedDB
        const userIsLogged = dbAccounts.find((d) => d.id === this.getId);
        if (userIsLogged) {
          await database.deleteAccount(userIsLogged.id);
          dbAccounts = dbAccounts.filter((d) => d.id !== this.getId);
        }

        // Récupère les données des comptes
        const { data, error } = await getAllMultiUser(dbAccounts.map((d) => d.id));

        if (error || !data) {
          this.setState("error");
          return;
        }

        // Ajoute les données des comptes à l'objet multiAccount
        for (const u of data) {
          const db = dbAccounts.find((d) => d.id === u.id);
          if (!db) continue;

          const decodedToken: DecodedJwtToken | undefined = getDecodedToken(db.token);
          if (!decodedToken) continue;
          this.multiAccount.users.push({
            ...u,
            exp: decodedToken.exp,
            iat: decodedToken.iat
          });
        }

        this.setState("success");
      } catch (_) {
        this.setState("error");
      }
    }
  }
});

function setRole(role: string): string {
  if (role !== "mixte") return role;

  const roleStorage: string | null = localStorage.getItem("role");

  // Évite que l'utilisateur change son rôle à la main et mette n'importe quoi
  if (!roleStorage || !["participant", "particulier"].includes(roleStorage)) {
    localStorage.setItem("role", "participant");
    return "participant";
  }

  return roleStorage;
}

export default useUserStore;
