import { deleteToken } from "@/utils/tokenUtils";
import Method from "@/utils/axiosMethods";
import { ApiResult } from "@/interfaces/api.interface";
import { useStore } from "@/stores";
import { LoginDto } from "@/validations/Login.dto";

const URL_BASE: string = "/api/v1/auth";

export async function authentification(login: LoginDto): Promise<ApiResult<{ token: string }>> {
  login.email = login.email.toLowerCase();
  return await Method.post(URL_BASE, login, { withCredentials: true });
}

export async function switchAccount(
  login: LoginDto
): Promise<ApiResult<{ token: string; oldToken: string }>> {
  login.email = login.email.toLowerCase();
  return await Method.post(`${URL_BASE}/switch`, login, { withCredentials: true });
}

export async function refreshToken(): Promise<ApiResult<{ token: string }>> {
  return await Method.post(`${URL_BASE}/refresh`, {}, { withCredentials: true });
}

export async function switchToken(
  token: string
): Promise<ApiResult<{ token: string; oldToken: string }>> {
  return await Method.post(`${URL_BASE}/switch-token`, { token }, { withCredentials: true });
}

export async function logout(): Promise<void> {
  await Method.get(`${URL_BASE}/logout`, { withCredentials: true });
  useStore().user.logout();
  deleteToken();
}
