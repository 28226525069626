import { createApp } from "vue";
import { createPinia, Pinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import PeLibrary from "./plugins/pe.components";
import Vue3Lottie from "vue3-lottie";
import "./tailwind.css";
import { IndexedDatabase } from "./utils/indexedDatabase";
import { createHead } from "@unhead/vue";

const app = createApp(App);
const pinia: Pinia = createPinia();
const database: IndexedDatabase = new IndexedDatabase();
const head = createHead();

app
  .provide("database", database)
  .use(pinia)
  .use(router)
  .use(PeLibrary)
  .use(Vue3Lottie, { name: "Vue3Lottie" })
  .use(head)
  .mount("#app");
