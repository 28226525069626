<script setup lang="ts">
import type { PropType } from "vue";

interface PaginationSettings {
  selected: number;
  max: number;
}

const emits = defineEmits(["getEmitedPage"]);

defineProps({
  settings: {
    type: Object as PropType<PaginationSettings>,
    default: null
  }
});
</script>

<template>
  <div v-if="settings.max > 1" class="flex flex-row w-fit gap-2 justify-end select-none px-0.5">
    <!-- Previous -->
    <span
      v-if="settings.max > 2 && settings.selected !== 1"
      class="w-full sm:w-fit h-7 cursor-pointer px-1.5 flex items-center justify-center bg-white shadow-sm text-lc-gray-arsenic rounded"
      @click="emits('getEmitedPage', 1)"
    >
      <img src="@/assets/icons/iconsax/linear/double-arrow-left-1.svg" class="w-4 h-4" />
    </span>
    <span
      v-if="settings.max > 1 && settings.selected !== 1"
      class="w-full sm:w-fit h-7 cursor-pointer px-0.5 flex items-center justify-center bg-white shadow-sm text-lc-gray-arsenic rounded"
      @click="settings.selected > 1 ? emits('getEmitedPage', settings.selected - 1) : ''"
    >
      <img src="@/assets/icons/iconsax/linear/arrow-left-1.svg" alt="arrow-left" class="w-4 h-4" />
    </span>

    <!-- Pages -->
    <div class="flex flex-row w-fit gap-1">
      <span
        v-if="settings.selected > 4 && settings.selected > settings.max - 1 && settings.max - 4 > 0"
        class="w-7 h-7 cursor-pointer flex items-center justify-center"
        @click="emits('getEmitedPage', settings.selected - 4)"
      >
        {{ settings.selected - 4 }}
      </span>
      <span
        v-if="settings.selected > 3 && settings.selected > settings.max - 2 && settings.max - 3 > 0"
        class="w-7 h-7 cursor-pointer flex items-center justify-center"
        @click="emits('getEmitedPage', settings.selected - 3)"
      >
        {{ settings.selected - 3 }}
      </span>
      <span
        v-if="settings.selected > 2 && settings.max - 2 > 0"
        class="w-7 h-7 cursor-pointer flex items-center justify-center"
        @click="emits('getEmitedPage', settings.selected - 2)"
      >
        {{ settings.selected - 2 }}
      </span>
      <span
        v-if="settings.selected > 1 && settings.max - 1 > 0"
        class="w-7 h-7 cursor-pointer flex items-center justify-center"
        @click="emits('getEmitedPage', settings.selected - 1)"
      >
        {{ settings.selected - 1 }}
      </span>

      <span
        class="bg-lc-primary shadow-md text-white rounded w-7 h-7 cursor-pointer flex items-center justify-center"
        @click="emits('getEmitedPage', settings.selected)"
      >
        {{ settings.selected }}
      </span>

      <span
        v-if="settings.selected < settings.max"
        class="w-7 h-7 cursor-pointer flex items-center justify-center"
        @click="emits('getEmitedPage', settings.selected + 1)"
      >
        {{ settings.selected + 1 }}
      </span>
      <span
        v-if="settings.selected + 2 <= settings.max"
        class="w-7 h-7 cursor-pointer flex items-center justify-center"
        @click="emits('getEmitedPage', settings.selected + 2)"
      >
        {{ settings.selected + 2 }}
      </span>
      <span
        v-if="settings.selected < 3 && settings.selected + 3 <= settings.max"
        class="w-7 h-7 cursor-pointer flex items-center justify-center"
        @click="emits('getEmitedPage', settings.selected + 3)"
      >
        {{ settings.selected + 3 }}
      </span>
      <span
        v-if="settings.selected < 2 && settings.selected + 4 <= settings.max"
        class="w-7 h-7 cursor-pointer flex items-center justify-center"
        @click="emits('getEmitedPage', settings.selected + 4)"
      >
        {{ settings.selected + 4 }}
      </span>
    </div>

    <!-- Next -->
    <span
      v-if="settings.max > 1 && settings.selected !== settings.max"
      class="w-full sm:w-fit h-7 cursor-pointer px-0.5 flex items-center justify-center bg-white shadow-sm text-lc-gray-arsenic rounded"
      @click="
        settings.selected !== settings.max ? emits('getEmitedPage', settings.selected + 1) : ''
      "
    >
      <img
        src="@/assets/icons/iconsax/linear/arrow-right-1.svg"
        alt="arrow-right"
        class="w-4 h-4"
      />
    </span>
    <span
      v-if="settings.max > 2 && settings.selected !== settings.max"
      class="w-full sm:w-fit h-7 cursor-pointer px-1.5 flex items-center justify-center bg-white shadow-sm text-lc-gray-arsenic rounded"
      @click="emits('getEmitedPage', settings.max)"
    >
      <img src="@/assets/icons/iconsax/linear/double-arrow-right-1.svg" class="w-4 h-4" />
    </span>
  </div>
</template>
