<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, type PropType, ref, watch } from "vue";
import {
  differenceInMinutes,
  endOfWeek,
  format,
  isBefore,
  isWeekend,
  nextMonday,
  startOfWeek,
  subDays
} from "date-fns";
import { fr } from "date-fns/locale/fr";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import { TransitionRoot } from "@headlessui/vue";
import type {
  AbsenceEvent,
  CoachEvent,
  ManagerEvent,
  ParticipantEvent
} from "@/interfaces/components/PeCalendar.interface";
import { onClickOutside } from "@vueuse/core";

const emits = defineEmits(["refresh", "deleteEvent", "seeEvent", "openAbsenceModal"]);

const props = defineProps({
  type: {
    type: String as PropType<"participant" | "manager" | "coach">,
    default: "participant"
  },
  events: {
    type: Array as PropType<ParticipantEvent[] | CoachEvent[] | ManagerEvent[]>,
    default: () => []
  },
  absences: {
    type: Array as PropType<AbsenceEvent[]>,
    default: () => []
  },
  hideWeekends: {
    type: Boolean,
    default: true
  },
  date: {
    type: String as PropType<string | null>,
    default: null
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const calendarLabel = ref<string>("");
const windowWidth = ref<number>(window.innerWidth);
const disabledViews = ref<string[]>(["years", "year", "month", "day"]);
const maxWidthForMobile = ref<number>(900);

// Calendar date picker
const selectedDate = ref<Date>(props.date ? new Date(props.date) : new Date());
const isDatePickerDisplayed = ref<boolean>(false);

// Refs
const vueCalendarLabel = ref(null);
const vueCalendar = ref(null);
const vueCalendarPicker = ref(null);

// Init isMobile with start values, will be updated when threshold is reached
const isMobile = computed(() => windowWidth.value <= maxWidthForMobile.value);
const activeView = computed(() => (isMobile.value ? "day" : "week"));

onClickOutside(vueCalendarPicker, () => {
  isDatePickerDisplayed.value = false;
});

onMounted(() => {
  window.addEventListener("resize", onResize);

  // Si on est un week-end, on affiche le lundi suivant
  if (isWeekend(new Date())) {
    selectedDate.value = nextMonday(new Date());
    setCalendarLabel(selectedDate.value);
  }

  // Mode bureau
  if (!isMobile.value) {
    fetchData();
    return;
  }

  // Mode mobile
  changeCalendarView(true);
  fetchData(undefined, new Date(selectedDate.value));
});

onBeforeUnmount(() => window.removeEventListener("resize", onResize));

async function goToCurrentWeek() {
  if (!props.isLoading) {
    (vueCalendar.value as any).switchView("week", new Date());
    await fetchData(undefined, new Date());
    await setCalendarLabel(new Date());
  }
}

async function onResize() {
  windowWidth.value = window.innerWidth;
}

async function fetchData(type?: string, date?: Date) {
  const vuecal = vueCalendar.value as any;

  if (date) {
    await handleDateSelection(vuecal, date);
  } else {
    await handleTypeSelection(vuecal, type);
  }
  await setCalendarLabel(date);
  await emitRefresh(vuecal.view.startDate, vuecal.view.endDate);
}

async function handleDateSelection(vuecal: any, date: Date) {
  isDatePickerDisplayed.value = false;
  selectedDate.value = date;
  vuecal.switchView(activeView.value, date);

  if (activeView.value === "week") {
    vuecal.view.startDate = startOfWeek(selectedDate.value, { weekStartsOn: 1 });
    vuecal.view.endDate = subDays(endOfWeek(selectedDate.value, { weekStartsOn: 1 }), 2);
    await setCalendarLabel();
  }
}

async function handleTypeSelection(vuecal: any, type?: string) {
  do {
    if (type === "next") {
      vuecal.next();
    } else if (type === "previous") {
      vuecal.previous();
    }
  } while (isWeekend(vuecal.view.startDate) && props.type !== "coach");

  selectedDate.value = vuecal.view.startDate;
  await setCalendarLabel();
}

async function changeCalendarView(isMobile: boolean) {
  disabledViews.value = isMobile
    ? ["years", "year", "month", "week"]
    : ["years", "year", "month", "day"];

  await setCalendarLabel(isMobile ? selectedDate.value : undefined);
}

async function setCalendarLabel(date?: Date) {
  const vuecal = vueCalendar.value as any;
  const { startDate, endDate } = vuecal.view;

  calendarLabel.value = isMobile.value
    ? format(date ?? startDate, "EEEE dd MMMM", { locale: fr })
    : await formatWeekLabel(startDate, endDate);
}

async function formatWeekLabel(startDate: Date, endDate: Date) {
  const startDateDay = format(startDate, "dd");
  const startDateMonth = format(startDate, "MMMM", { locale: fr });
  const endDateDay = format(endDate, "dd");
  const endDateMonth = format(endDate, "MMMM", { locale: fr });

  if (startDateMonth === endDateMonth) {
    return `Semaine du ${startDateDay} au ${endDateDay} ${endDateMonth}`;
  } else {
    return `Semaine du ${startDateDay} ${startDateMonth} au ${endDateDay} ${endDateMonth}`;
  }
}

async function emitRefresh(startDate: Date, endDate: Date) {
  emits("refresh", startDate, endDate);
}

/**
 * On définit le background en fonction du status de la séance
 * Si la séance est réalisée, on affiche un fond vert
 * Sinon, on affiche un fond indigo (bleu)
 */
function getBg(event: any) {
  let bg = "bg-opacity-20 border-l-2 rounded h-full w-full overflow-hidden py-1 pl-3 pr-1";
  if (props.type === "coach") bg += " flex justify-between";
  if (event.status === "R") {
    return `${bg} bg-green-300 border-green-800 text-green-800`;
  } else if (props.type === "coach" && isBefore(new Date(event.end), new Date())) {
    return `${bg} bg-red-300 border-red-600 text-red-800`;
  } else {
    return `${bg} bg-indigo-300 border-indigo-600 text-indigo-800`;
  }
}

/**
 * On définit l'icon en fonction du type de séance et si elle est réalisée ou non
 * @param { CoachEvent } event - Séance du coach
 * @returns { string } - URL de l'icon
 */
function getIcon(event: CoachEvent): string {
  if (event.status === "R") {
    // Si la séance est réalisée
    if (event.type === "company") {
      return new URL("@/assets/icons/iconsax/bold/work-green-800.svg", import.meta.url).href;
    } else {
      return new URL("@/assets/icons/iconsax/bold/house-1-green-800.svg", import.meta.url).href;
    }
  } else if (isBefore(new Date(event.end), new Date())) {
    // Si la séance est passée et non réalisée
    if (event.type === "company") {
      return new URL("@/assets/icons/iconsax/bold/work-red-800.svg", import.meta.url).href;
    } else {
      return new URL("@/assets/icons/iconsax/bold/house-1-red-800.svg", import.meta.url).href;
    }
  } else if (event.type === "company") {
    return new URL("@/assets/icons/iconsax/bold/work-indigo-800.svg", import.meta.url).href;
  } else {
    return new URL("@/assets/icons/iconsax/bold/house-1-indigo-800.svg", import.meta.url).href;
  }
}

/**
 * On affiche la séance si la durée est supérieur ou égale à 30 minutes
 * @param { ParticipantEvent | CoachEvent | ManagerEvent } event - Séance
 * @returns { "block" | "hidden" } - Affichage de la séance
 */
async function show(
  event: ParticipantEvent | CoachEvent | ManagerEvent
): Promise<"block" | "hidden"> {
  const diff = differenceInMinutes(new Date(event.end), new Date(event.start));
  return diff >= 30 ? "block" : "hidden";
}

watch(isMobile, (newIsMobile) => {
  changeCalendarView(newIsMobile);
  fetchData(undefined, selectedDate.value);
});
</script>

<template>
  <div class="bg-white rounded-xl sm:p-8 shadow-lg overflow-hidden">
    <div class="h-full flex flex-col sm:gap-6">
      <!-- Menu au dessus du calendrier -->
      <div
        class="w-full flex flex-col-reverse sm:flex-row gap-4 sm:gap-0 items-center justify-between"
      >
        <div class="flex gap-2 items-center w-full">
          <div
            ref="vueCalendarLabel"
            class="relative h-9 w-full sm:w-fit border-gray-300 sm:border rounded flex flex-row items-center justify-between"
          >
            <button class="px-2 h-full" @click="!isLoading ? fetchData('previous') : ''">
              <img
                src="@/assets/icons/iconsax/linear/arrow-left-1.svg"
                class="w-5 h-5"
                title="Semaine précédente"
                alt="Semaine précédente"
              />
            </button>
            <p
              class="font-medium text-center text-lc-gray-arsenic cursor-pointer select-none shrink sm:grow w-[350px]"
              @click="isDatePickerDisplayed = !isDatePickerDisplayed"
            >
              {{ calendarLabel }}
            </p>
            <button class="px-2 h-full" @click="!isLoading ? fetchData('next') : ''">
              <img
                src="@/assets/icons/iconsax/linear/arrow-right-1.svg"
                class="w-5 h-5"
                title="Semaine prochaine"
                alt="Semaine prochaine"
              />
            </button>
            <TransitionRoot
              :show="isDatePickerDisplayed"
              appear
              as="template"
              enter="transform transition duration-800 ease-out"
              enter-from="opacity-0 -translate-y-6"
              enter-to="opacity-100 translate-y-0"
              leave="transform transition duration-400 ease-in"
              leave-from="opacity-100 translate-y-0"
              leave-to="opacity-0 -translate-y-6"
            >
              <div class="w-full absolute left-0 top-11 z-[999]">
                <!-- Picker de date -->
                <VueCal
                  ref="vueCalendarPicker"
                  xsmall
                  :time="false"
                  hide-view-selector
                  active-view="month"
                  :hide-weekends="hideWeekends"
                  locale="fr"
                  :disable-views="['years', 'year', 'week', 'day']"
                  :selected-date="selectedDate"
                  @cell-click="fetchData(undefined, $event), setCalendarLabel()"
                  @mouseleave="isDatePickerDisplayed = false"
                  class="mx-auto vuecal--date-picker calendar-date-picker shadow-lg border border-gray-200"
                  style="max-width: 270px; height: 290px"
                />
              </div>
            </TransitionRoot>
          </div>

          <!-- Btn: Revenir à la semaine courante -->
          <div
            class="hidden sm:flex bg-indigo-100 items-center justify-center rounded cursor-pointer"
            title="Revenir à la semaine courante"
            @click="goToCurrentWeek"
          >
            <img
              src="@/assets/icons/iconsax/linear/calendar.svg"
              alt="Revenir à la semaine courante"
              class="min-w-6 min-h-6 m-2"
            />
          </div>
        </div>

        <!-- Btn: Prévenir de mon absence (uniquement pour le cal "Mes séances") -->
        <pe-btn
          v-if="type === 'participant'"
          label="Prévenir de mon absence"
          size="base"
          type="primary"
          class="lg:mt-0 mt-4"
          @click="emits('openAbsenceModal')"
        />
      </div>

      <!-- Calendrier en personne messieurs dames -->
      <VueCal
        ref="vueCalendar"
        :time-from="6 * 60"
        :time-to="22 * 60"
        :active-view="activeView"
        :disable-views="disabledViews"
        locale="fr"
        :timeCellHeight="70"
        today-button
        hide-view-selector
        hide-title-bar
        :hide-weekends="hideWeekends"
        :events="[...events, ...absences]"
        class="calendar"
        :selected-date="selectedDate"
      >
        <!-- Override Event Card -->
        <template v-slot:event="{ event }">
          <div
            v-if="!event.background"
            pw="event-card"
            class="h-full w-full px-1 py-0.5 block cursor-pointer relative z-10"
            @click="emits('seeEvent', event.id)"
          >
            <div :class="getBg(event)">
              <div class="flex flex-col gap-px text-left h-full">
                <p class="text-sm font-semibold w-fit h-fit">
                  {{ event.title }}
                </p>
                <p :class="['text-xs w-fit h-fit', show(event)]">
                  {{ event.content }}
                </p>
              </div>

              <img v-if="type === 'coach'" :src="getIcon(event)" alt="" class="w-5 h-5 ml-0.5" />
            </div>
          </div>
          <div
            v-else
            class="h-full w-full flex flex-col items-center bg-orange-100/20 relative z-0 bg-absence"
          ></div>
        </template>
      </VueCal>
    </div>
  </div>
</template>

<style lang="scss">
// Calendar date picker
.calendar-date-picker {
  background-color: white;
  box-shadow: none;
  border-radius: 8px;
  overflow: hidden;
  padding-top: 4px;

  .vuecal__title-bar {
    background-color: white;
  }

  .vuecal__cell--today .vuecal__cell-content {
    // lc secondary color
    border-color: #535f9d !important;
  }

  .vuecal__cell--selected .vuecal__cell-content {
    // lc secondary color
    background-color: #535f9d !important;
  }
}

// Calendar
.calendar {
  background-color: white;
  overflow-y: auto;

  .vuecal__now-line {
    z-index: 9;
  }

  .vuecal__title-bar {
    background-color: white;
    font-size: 14px;
    padding: 15px;
  }

  .vuecal__title > span {
    font-weight: 500;
    font-size: 1rem;
    color: #404147; // Arsenic gray
  }

  .vuecal__arrow {
    border-radius: 20px;
    border: 2px solid #e5e7eb;
    height: 40px;
    width: 44px;
    outline: none;
    padding: 0;
    color: #5700ff;

    & i {
      font-size: 17px;
    }

    &--prev {
      padding-left: 4px;
    }

    &--next {
      padding-right: 4px;
    }
  }

  // Remove first schedule text and border top
  .vuecal__time-cell:first-child {
    font-size: 0;

    .vuecal__time-cell-line:first-child:before {
      border-top: none;
    }
  }

  .vuecal__time-cell {
    text-align: center;
    color: #666666;

    &-label {
      position: relative;
      top: -8px;
    }

    &-line:before {
      left: 49px;
      border-top: 1px solid #e5e7eb;
    }

    & > .minutes:before {
      content: "";
      position: absolute;
      right: 0;
    }
  }

  .weekday-label {
    cursor: auto !important;
  }

  .weekday-label > span {
    color: #404147; // Arsenic gray
    font-weight: 400;
    font-size: 1rem;
  }

  .vuecal__weekdays-headings {
    z-index: 2;
    border: 1px solid #e5e7eb;
    margin-bottom: 0;
  }

  .vuecal__cell:before {
    border: none;
    z-index: auto;
    border-left: 1px solid #e5e7eb;
    border-right: 1px solid #e5e7eb;
  }

  .vuecal__cell--selected {
    background-color: transparent;
    z-index: auto;
    outline: none;
  }

  .vuecal__cell--today {
    background-color: #c6c9ff32;
    z-index: 1;
  }

  // Override card slot
  .vuecal__event {
    background-color: transparent !important;
    overflow: visible;
    z-index: auto;
    transition: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .bg-absence {
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      #f2f2f2 10px,
      #f2f2f2 20px
    ); /* IE 10+ */
  }

  .vuecal__event--focus {
    outline: none;
    box-shadow: none;
  }

  .vuecal__no-event {
    display: none;
  }
}
</style>
