import { format } from "date-fns";
import type { Repetition } from "@/interfaces/repetition.interface";

export function formatOgustDay(day: number): string {
  switch (day) {
    case 1:
      return "lundi";
    case 2:
      return "mardi";
    case 3:
      return "mercredi";
    case 4:
      return "jeudi";
    case 5:
      return "vendredi";
    case 6:
      return "samedi";
    case 7:
      return "dimanche";
    default:
      return "Day not found";
  }
}

export function formatOgustDayIndispo(day: number): string {
  switch (day) {
    case 1:
      return "lundi";
    case 2:
      return "mardi";
    case 3:
      return "mercredi";
    case 4:
      return "jeudi";
    case 5:
      return "vendredi";
    case 6:
      return "samedi";
    case 0:
      return "dimanche";
    default:
      return "Day not found";
  }
}

export function formatOgustHour(
  startHour: string | Date,
  endHour: string | Date,
  delimiter: string
): string {
  let response: string = "Hours not found";

  if (!startHour || !endHour) return response;

  let start: string;
  let end: string;

  if (typeof startHour === "string" && typeof endHour === "string" && startHour.length < 10) {
    start = startHour.replaceAll(":", "");
    end = endHour.replaceAll(":", "");
  } else {
    startHour = format(new Date(startHour), "yyyyMMddHHmm");
    endHour = format(new Date(endHour), "yyyyMMddHHmm");
    start = startHour.substring(startHour.length - 4);
    end = endHour.substring(endHour.length - 4);
  }

  response = `${start.substring(0, 2)}h${start.substring(2, 4)}
  ${delimiter} ${end.substring(0, 2)}h${end.substring(2, 4)}`;

  return response;
}

export function formatOgustDate(date: string): Date {
  return new Date(
    format(
      new Date(+date.substring(0, 4), +date.substring(4, 6) - 1, +date.substring(6, 8)),
      "yyyy/MM/dd"
    )
  );
}

export function formatOgustDateTime(date: string): Date {
  return new Date(
    format(
      new Date(
        +date.substring(0, 4),
        +date.substring(4, 6) - 1,
        +date.substring(6, 8),
        +date.substring(8, 10),
        +date.substring(10, 12)
      ),
      "yyyy/MM/dd HH:mm"
    )
  );
}

// Sort by weekday and hour
export function sortRepetitionsByDayHour(repetitions: Repetition[]) {
  return repetitions.sort((a, b) => {
    // Sort by day then by start hour. To give priority to the day we use pow
    return Math.pow(a.day, 100) - Math.pow(b.day, 100) + (+a.start_hour - +b.start_hour);
  });
}
