import { AxiosSingleton } from "./authorizedAxiosInstance";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResult } from "@/interfaces/api.interface";

const axiosInstance = AxiosSingleton.getInstance();

class Method {
  /**
   * Récupère des données depuis l'API
   * @param url URL pour récupérer des données
   * @param config Axios config
   * @returns {Promise<{ data?: any; error?: APIError }>} data ou error
   */
  async get(url: string, config?: AxiosRequestConfig<any>): Promise<ApiResult<any>> {
    const axios: AxiosInstance = await axiosInstance.getAxiosInstance();

    try {
      const { data, status } = await axios.get(url, config);
      return { data, status };
    } catch (error: any) {
      return { error: error.response?.data };
    }
  }

  /**
   * Envoie des données à l'API
   * @param url URL pour envoyer des données
   * @param data Data à envoyer
   * @param config Axios config
   * @returns {Promise<{ data?: any; error?: APIError }>} data ou error
   */
  async post(url: string, data?: any, config?: AxiosRequestConfig<any>): Promise<ApiResult<any>> {
    const axios: AxiosInstance = await axiosInstance.getAxiosInstance();

    try {
      const res: AxiosResponse<any, any> = await axios.post(url, data, config);
      return { data: res.data, status: res.status };
    } catch (error: any) {
      return { error: error.response?.data };
    }
  }

  /**
   * Envoie des données à l'API
   * @param url URL pour envoyer des données
   * @param data Data à envoyer
   * @param config Axios config
   * @returns {Promise<{ data?: any; error?: APIError }>} data ou error
   */
  async patch(url: string, data?: any, config?: AxiosRequestConfig<any>): Promise<ApiResult<any>> {
    const axios: AxiosInstance = await axiosInstance.getAxiosInstance();

    try {
      const res: AxiosResponse<any, any> = await axios.patch(url, data, config);
      return { data: res.data, status: res.status };
    } catch (error: any) {
      return { error: error.response?.data };
    }
  }

  /**
   * Supprime des données depuis l'API
   * @param url URL pour supprimer des données
   * @param config Axios config
   * @returns {Promise<{ data?: any; error?: APIError }>} data ou error
   */
  async delete(url: string, config?: AxiosRequestConfig<any>): Promise<ApiResult<any>> {
    const axios: AxiosInstance = await axiosInstance.getAxiosInstance();

    try {
      const { data, status } = await axios.delete(url, config);
      return { data, status };
    } catch (error: any) {
      return { error: error.response?.data };
    }
  }
}

export default new Method();
