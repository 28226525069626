<script setup lang="ts">
import { PropType } from "vue";
import { PillType } from "@/interfaces/components/PePill.interface";

defineProps({
  type: {
    type: String as PropType<PillType>,
    default: "gray"
  },
  label: {
    type: String,
    default: "Texte"
  }
});

function getColorClass(type: PillType, tag: "background" | "label") {
  switch (type) {
    case "blue":
      if (tag === "background") return "bg-blue-100";
      else return "text-blue-700";
    case "green":
      if (tag === "background") return "bg-emerald-100";
      else return "text-emerald-700";
    case "red":
      if (tag === "background") return "bg-red-100";
      else return "text-red-700";
    case "yellow":
      if (tag === "background") return "bg-yellow-100";
      else return "text-yellow-700";
    case "orange":
      if (tag === "background") return "bg-orange-100";
      else return "text-orange-700";
    case "gray":
      if (tag === "background") return "bg-gray-200";
      else return "text-gray-700";
    case "pink":
      if (tag === "background") return "bg-pink-100";
      else return "text-pink-700";
    case "purple":
      if (tag === "background") return "bg-purple-100";
      else return "text-purple-700";
    case "rose":
      if (tag === "background") return "bg-rose-100";
      else return "text-rose-700";
    case "cyan":
      if (tag === "background") return "bg-cyan-100";
      else return "text-cyan-700";
    case "indigo":
      if (tag === "background") return "bg-indigo-100";
      else return "text-indigo-700";
    case "teal":
      if (tag === "background") return "bg-teal-100";
      else return "text-teal-700";
    case "sky":
      if (tag === "background") return "bg-sky-100";
      else return "text-sky-700";
  }
}
</script>

<template>
  <div
    class="rounded-full w-fit h-fit flex items-center justify-center"
    :class="getColorClass(type, 'background')"
  >
    <p
      class="text-xs font-medium h-fit py-1 w-fit px-3 whitespace-nowrap"
      :class="getColorClass(type, 'label')"
    >
      {{ label }}
    </p>
  </div>
</template>
