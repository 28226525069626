import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { useStore } from "@/stores";
import type { DecodedJwtToken } from "@/interfaces/jwtToken.interface";
import { refreshToken } from "@/services/auth";
import { addMinutes } from "date-fns";

export function getToken(): string {
  return Cookies.get("accessToken") ?? "";
}

export function getDecodedToken(token: string): DecodedJwtToken | undefined {
  if (!token) throw new Error("Empty token");
  try {
    return jwtDecode(token);
  } catch (_) {
    Cookies.remove("accessToken");
  }
}

export function createToken(token: string): void {
  createTokenCookie(token);
  createUserByToken(token);
}

export function createUserByToken(token: string): void {
  const decodedToken: DecodedJwtToken | undefined = getDecodedToken(token);
  if (!decodedToken) return;
  useStore().user.login(decodedToken);
}

export function createTokenCookie(token: string): void {
  Cookies.set("accessToken", token, {
    expires: addMinutes(new Date(), 60),
    sameSite: "strict" // Cookie is exclusive to the current website
  });
}

export async function updateToken(): Promise<void> {
  const { data } = await refreshToken();
  if (!data) return;
  deleteToken();
  createToken(data.token);
}

export function deleteToken(): void {
  Cookies.remove("accessToken");
}
