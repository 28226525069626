<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import errorIcon from "@/assets/icons/iconsax/bold/info-circle-red-500.svg";
import passwordIcon from "@/assets/icons/iconsax/bold/eye.svg";
import passwordIconVisible from "@/assets/icons/iconsax/bold/eye-slash.svg";
import { format } from "date-fns";

const emits = defineEmits(["update:modelValue", "deleteError"]);

const props = defineProps({
  modelValue: {
    type: String as PropType<string | number | Date>,
    default: ""
  },
  label: {
    type: String,
    default: ""
  },
  name: {
    type: String
  },
  type: {
    type: String as PropType<
      | "button"
      | "checkbox"
      | "color"
      | "date"
      | "datetime-local"
      | "email"
      | "file"
      | "hidden"
      | "image"
      | "month"
      | "number"
      | "password"
      | "radio"
      | "range"
      | "reset"
      | "search"
      | "submit"
      | "tel"
      | "text"
      | "time"
      | "url"
      | "week"
      | "textarea"
    >,
    default: "text"
  },
  placeholder: {
    type: String,
    default: ""
  },
  autocapitalize: {
    type: String,
    default: "on"
  },
  iconType: {
    type: String,
    default: "linear"
  },
  iconName: {
    type: String,
    default: ""
  },
  readonly: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ""
  },
  size: {
    type: String as PropType<"sm" | "xs" | "base">,
    default: "base"
  },
  minDate: {
    type: Date,
    default: new Date()
  },
  maxDate: {
    type: Date,
    default: new Date("2099-12-31")
  },
  minLength: {
    type: Number,
    default: null
  },
  maxLength: {
    type: Number,
    default: null
  },
  min: {
    type: Number || Date,
    default: null
  },
  max: {
    type: Number || Date,
    default: null
  },
  rows: {
    type: String,
    default: "5"
  },
  cols: {
    type: String,
    default: undefined
  },
  fieldClass: {
    type: String,
    default: ""
  },
  autocomplete: {
    type: String as PropType<"on" | "off" | "current-password" | "new-password">,
    default: "off"
  }
});

const visibility = ref<boolean>(false);

function toggleVisibility(): void {
  visibility.value = !visibility.value;
}

function change(value: string): void {
  if ((props.type === "date" || props.type === "datetime-local") && +value === 0 && value !== "") {
    return; // Parce que ça throw sinon
  }

  if (props.type === "number") {
    emits("update:modelValue", +value);
  } else {
    emits("update:modelValue", value);
  }

  if (props.error) emits("deleteError");
}

function formatValue(): string | number | Date {
  if (!props.modelValue) return "";
  if (props.type === "date") {
    return format(new Date(props.modelValue), "yyyy-MM-dd");
  }
  if (props.type === "datetime-local") {
    return format(new Date(props.modelValue), "yyyy-MM-dd'T'HH:mm");
  }
  return props.modelValue;
}

function getSvgLink(): string | undefined {
  if (!props.iconName) return;

  return new URL(
    `/src/assets/icons/iconsax/${props.iconType}/${props.iconName}.svg`,
    import.meta.url
  ).href;
}

function getSize(): string {
  switch (props.size) {
    case "xs":
      return "h-[34px] leading-[28px]";
    case "sm":
      return "h-[38px] leading-[32px]";
    case "base":
      return "h-[46px] leading-[40px]";
    default:
      return "";
  }
}

const getClass = computed(() => {
  return props.error
    ? "text-red-600 placeholder-red-400 border-red-300 outline-red-400"
    : "text-lc-gray-dimGray placeholder-lc-gray-quickSilver border-gray-300 outline-lc-primary";
});

const getMin = computed(() => {
  if (!["date", "datetime-local"].includes(props.type)) return "";

  let formatDate = "yyyy-MM-dd";
  if (props.type === "datetime-local") formatDate += "'T'HH:mm";
  return format(new Date(props.minDate), formatDate);
});

const getMax = computed(() => {
  if (!["date", "datetime-local"].includes(props.type)) return "";

  let formatDate = "yyyy-MM-dd";
  if (props.type === "datetime-local") formatDate += "'T'HH:mm";
  return format(new Date(props.maxDate), formatDate);
});

const getAutocomplete = computed(() => {
  if (props.type === "password") return "new-password";
  return props.autocomplete;
});
</script>

<template>
  <div :class="['flex flex-col h-fit', { 'gap-1': label }]">
    <label :for="name" class="text-sm font-medium text-lc-charcoal">
      {{ label }}
    </label>

    <!-- input ou textarea -->
    <div class="flex items-center relative h-fit">
      <label
        :for="name"
        class="absolute inset-y-0 flex select-none"
        :class="[
          type === 'textarea' ? 'bottom-[9px] right-[13px] items-end' : 'left-[13px] items-center'
        ]"
      >
        <img
          v-if="props.iconName || error"
          :src="error ? errorIcon : getSvgLink()"
          aria-hidden="true"
          alt=""
        />
      </label>
      <input
        v-if="type !== 'textarea'"
        :id="name"
        :name="name"
        :type="visibility ? 'text' : type"
        :value="formatValue()"
        :placeholder="placeholder"
        :readonly="readonly"
        :tabindex="readonly ? -1 : 0"
        :autocomplete="getAutocomplete"
        :maxlength="type === 'tel' ? 10 : maxLength ?? 999"
        :min="getMin"
        :max="getMax"
        :autocapitalize="autocapitalize"
        :pattern="type === 'number' ? '[0-9]*' : undefined"
        :inputmode="type === 'number' ? 'numeric' : undefined"
        class="border rounded-md bg-white pr-[3px] w-full shadow-sm"
        :class="[
          { 'opacity-75': readonly },
          iconName || error ? 'pl-[44px]' : 'pl-[13px]',
          getClass,
          getSize()
        ]"
        @input="change(($event?.target as HTMLInputElement).value)"
      />

      <div v-else class="relative w-full">
        <textarea
          v-if="typeof modelValue === 'string'"
          :id="name"
          :name="name"
          :value="modelValue"
          :placeholder="placeholder"
          :readonly="readonly"
          :tabindex="readonly ? -1 : 0"
          :rows="rows"
          :cols="cols"
          :minlength="minLength"
          :maxlength="maxLength"
          class="border rounded-md bg-white py-[9px] px-[13px] w-full h-full shadow-sm"
          :class="[fieldClass, { 'opacity-75': readonly }, getClass]"
          @input="change(($event?.target as HTMLInputElement).value)"
        ></textarea>
        <span
          v-if="maxLength && typeof modelValue === 'string'"
          class="absolute bottom-3 right-3 text-sm"
        >
          {{ modelValue.length + "/" + maxLength }}
        </span>
      </div>

      <span class="absolute -inset-y-0 right-[13px] flex items-center">
        <img
          v-if="type === 'password'"
          :src="visibility ? passwordIconVisible : passwordIcon"
          alt=""
          aria-hidden="true"
          class="text-lc-gray-dimGray w-7 h-7 select-none cursor-pointer"
          @click="toggleVisibility"
        />
      </span>
    </div>

    <!-- error -->
    <div v-if="error" class="text-ellipsis text-sm font-medium text-red-600">
      {{ error }}
    </div>
  </div>
</template>

<style scoped>
input[type="time"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="week"] {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}
</style>
