import type { App } from "vue";
import PeInput from "@/components/library/PeInput.vue";
import PeInputFile from "@/components/library/PeInputFile.vue";
import PeSelect from "@/components/library/PeSelect.vue";
import PeButton from "@/components/library/PeButton.vue";
import PeRadio from "@/components/library/PeRadio.vue";
import PeTabs from "@/components/library/PeTabs.vue";
import PeNotification from "@/components/library/PeNotification.vue";
import PeFeedback from "@/components/library/PeFeedback.vue";
import PeSteps from "@/components/library/PeSteps.vue";
import PePill from "@/components/library/PePill.vue";
import PeToggle from "@/components/library/PeToggle.vue";
import PeCalendar from "@/components/library/PeCalendar.vue";
import PeTable from "@/components/library/PeTable.vue";
import PeTableTd from "@/components/library/PeTableTd.vue";
import PePagination from "@/components/library/PePagination.vue";
import PeSwitch from "@/components/library/PeSwitch.vue";
import PeCombobox from "@/components/library/PeCombobox.vue";
import PePopover from "@/components/library/PePopover.vue";
import PeChart from "@/components/library/PeChart.vue";
import PeListbox from "@/components/library/PeListbox.vue";
import PeRadioGroup from "@/components/library/PeRadioGroup.vue";
import PeCheckbox from "@/components/library/PeCheckbox.vue";

export default (app: App): void => {
  app.component("pe-input", PeInput);
  app.component("pe-input-file", PeInputFile);
  app.component("pe-select", PeSelect);
  app.component("pe-btn", PeButton);
  app.component("pe-toggle", PeToggle);
  app.component("pe-switch", PeSwitch);
  app.component("pe-radio", PeRadio);
  app.component("pe-tabs", PeTabs);
  app.component("pe-notification", PeNotification);
  app.component("pe-feedback", PeFeedback);
  app.component("pe-steps", PeSteps);
  app.component("pe-calendar", PeCalendar);
  app.component("pe-combobox", PeCombobox);
  app.component("pe-popover", PePopover);
  app.component("pe-table", PeTable);
  app.component("pe-table-td", PeTableTd);
  app.component("pe-pagination", PePagination);
  app.component("pe-pill", PePill);
  app.component("pe-chart", PeChart);
  app.component("pe-listbox", PeListbox);
  app.component("pe-radio-group", PeRadioGroup);
  app.component("pe-checkbox", PeCheckbox);
};
