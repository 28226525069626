<script setup lang="ts">
import { type PropType, computed, onMounted } from "vue";

export interface Radio {
  label: string;
  value: boolean;
  disabled?: boolean;
}

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: Boolean || Number || String,
    required: true
  },
  radios: {
    type: Array as PropType<Radio[]>,
    default: () => []
  },
  defaultSelected: {
    type: Boolean,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  alignment: {
    type: String as PropType<"column" | "row">,
    default: "row"
  },
  customClass: {
    type: String,
    default: ""
  }
});

onMounted(() => {
  if (props.defaultSelected !== null) emits("update:modelValue", props.defaultSelected);
});

function setRadioValue(value: boolean) {
  emits("update:modelValue", value);
}

const selected = computed(() => props.modelValue);
</script>

<template>
  <div
    class="w-fit flex"
    :class="[{ 'gap-4': alignment === 'row' }, { 'flex-col gap-2': alignment === 'column' }]"
  >
    <label
      v-for="radio in radios"
      :key="radio.label"
      :class="`select-none cursor-pointer w-full ${customClass}`"
      @click="!radio.disabled && !disabled ? setRadioValue(radio.value) : ''"
    >
      <input
        type="radio"
        :value="radio.value"
        class="hidden"
        :checked="radio.value === selected"
        :disabled="radio.disabled || disabled"
        required
      />
      <div class="w-fit flex flex-row items-center gap-2">
        <span
          class="h-[1.125rem] w-[1.125rem] flex items-center justify-center border rounded-full border-lc-gray-quickSilver"
          :class="radio.value === selected ? 'bg-lc-primary box-shadow-radio' : 'bg-white'"
        >
        </span>
        <p class="text-lc-gray-blackCoral text-base whitespace-nowrap">{{ radio.label }}</p>
      </div>
    </label>
  </div>
</template>

<style scoped>
.box-shadow-radio {
  box-shadow: 0px 0px 0px 2.2px white inset;
}
</style>
