<script setup lang="ts">
import { PropType } from "vue";

export interface Checkbox {
  label: string;
  value: string | boolean;
  checked?: boolean;
  input?: {
    display?: boolean;
    type?: string;
    placeholder?: string;
    value?: string;
    min?: number;
  };
}

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: Array as PropType<Checkbox[]>
  },
  label: {
    type: String,
    default: ""
  },
  data: {
    type: Array as PropType<Checkbox[]>,
    default: () => []
  },
  radioSelect: {
    type: Boolean,
    default: false
  },
  isDeselectable: {
    type: Boolean,
    default: false
  },
  alignement: {
    type: String as PropType<"row" | "column">,
    default: "column"
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

/**
 * Update le modelValue à chaque fois qu'une checkbox est cochée ou décochée
 * On transforme le dataset.id parce qu'il renvoie une string
 * @async
 * @param { Event } e - L'évènement récupéré lors du click sur la checkbox
 * @emits update:modelValue - Émet l'évènement update:modelValue avec le nouveau modelValue
 * @returns { Promise<void> }
 */
async function update(e: Event): Promise<void> {
  if (!props.modelValue) return;
  const data = props.modelValue;

  const dataset = (e.target as HTMLInputElement).dataset.id;
  const id = await setDataset(dataset);
  const value = (e.target as HTMLInputElement).checked;

  // Si on est en mode radioSelect, on décoche toutes les autres checkbox
  if (props.radioSelect) {
    if (!value && !props.isDeselectable) {
      (e.target as HTMLInputElement).checked = true;
      return;
    }
    data.forEach((checkbox) => (checkbox.checked = false));
  }

  const index = data.findIndex((checkbox) => checkbox.value === id);
  if (index === -1) return;
  data[index].checked = value;

  emits("update:modelValue", data);
}

async function setDataset(dataset: string | undefined): Promise<string | boolean> {
  if (!dataset) return "";
  if (dataset === "true") return true;
  else if (dataset === "false") return false;
  else return dataset;
}
</script>

<template>
  <div>
    <p v-if="label" class="text-sm font-medium text-lc-charcoal mb-2 cursor-default">{{ label }}</p>
    <div :class="['flex gap-2', alignement === 'column' ? 'flex-col' : 'flex-wrap']">
      <template v-for="checkbox of modelValue" :key="checkbox">
        <label class="flex gap-2 items-center select-none cursor-pointer">
          <input
            :data-id="checkbox.value"
            type="checkbox"
            class="daisy-checkbox checkbox-lc-primary"
            :checked="checkbox.checked"
            :disabled="disabled"
            @change="update"
          />
          {{ checkbox.label }}
        </label>
        <pe-input
          v-if="checkbox.input?.display && checkbox.checked"
          v-model="checkbox.input.value"
          :type="checkbox.input.type ?? 'text'"
          :placeholder="checkbox.input.placeholder"
          :min="checkbox.input.min"
          :readonly="disabled"
          class="input input-lc-primary"
        />
      </template>
    </div>
  </div>
</template>
