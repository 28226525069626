<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption
} from "@headlessui/vue";
import iconWhite from "@/assets/icons/iconsax/bold/tick-circle-white.svg";
import iconPrimary from "@/assets/icons/iconsax/bold/tick-circle-primary.svg";
import iconGray from "@/assets/icons/iconsax/bold/tick-circle-gray-500.svg";
import { storeToRefs } from "pinia";
import { useStore } from "@/stores";

export interface Radio {
  value: number | string;
  label: string;
  description: string;
  disabled: boolean;
  alreadySelected: boolean;
}

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: Number as PropType<number | string | Object>,
    required: true
  },
  radios: {
    type: Array as PropType<Radio[]>,
    required: true
  },
  alignment: {
    type: String as PropType<"column" | "row" | "grid">,
    default: "column"
  },
  selectType: {
    type: String as PropType<"background" | "border" | "background-border">,
    default: "background"
  },
  layout: {
    type: String as PropType<"radio" | "box">,
    default: "box"
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const store = useStore();
const { isMobile } = storeToRefs(store.user);

const selected = ref(
  // Le modelValue peut être un objet ou un nombre
  props.radios.find((radio) => {
    if (typeof props.modelValue !== "object") {
      return radio.value === props.modelValue;
    }
  })
);

function update(radio: Radio): void {
  const type = typeof props.modelValue;
  const value = type === "object" ? radio : type === "number" ? +radio.value : radio.value;
  emits("update:modelValue", value);
}

// Gère les class à implémenter en fonction des props
// Passer dans des fonctions pour libérer le template et avoir plus de lisibilité
function cardStyle(
  checked: boolean,
  disabled: boolean,
  alreadySelected: boolean
): string[] | string {
  if (props.layout === "radio") return "";

  const class_ = ["rounded-lg px-5 py-4 border-2"];

  if (props.selectType === "background") class_.push("border-transparent shadow-md");
  else if (props.selectType === "border") class_.push("border-gray-300");
  else class_.push("border-gray-300 shadow-sm");

  if (disabled || props.disabled) {
    class_.push("bg-gray-100 cursor-not-allowed");
    return class_;
  }

  if (alreadySelected) {
    const checkedClass = checked
      ? "border-green-600"
      : "border-green-300 lg:hover:border-green-500";
    class_.push(`bg-green-100 ${checkedClass}`);
  } else if (checked) {
    if (props.selectType !== "border") class_.push("bg-lc-primary border-lc-primary text-white");
    else class_.push("border-lc-primary");
  } else if (!disabled) {
    class_.push("lg:hover:border-lc-secondary");
  }

  return class_;
}

function labelStyle(checked: boolean, disabled: boolean, alreadySelected: boolean): string {
  if (disabled || props.disabled) return "text-gray-400";
  if (alreadySelected) return "text-green-600";
  if (checked && props.selectType !== "border" && props.layout === "box") return "text-white";
  return "text-gray-900";
}

function descriptionStyle(checked: boolean, disabled: boolean, alreadySelected: boolean): string {
  if (disabled || props.disabled) return "text-gray-400";
  if (alreadySelected) return "text-green-600";
  if (checked && props.selectType !== "border") return "text-sky-100";
  return "text-gray-600";
}

const radioGroupStyle = computed(() => {
  if (props.alignment === "column") return "flex flex-col space-y-2";
  else if (props.alignment === "row") return "flex space-x-4";
  else return "grid grid-cols-1 md:grid-cols-3 gap-4";
});

const cardAlignment = computed(() => {
  if (props.layout === "radio") return "space-x-2";

  if (props.alignment === "column") return "justify-between";
  else return "justify-center";
});

const getIcon = computed(() => {
  if (props.disabled) return iconGray;
  if (props.selectType !== "border") return iconWhite;
  return iconPrimary;
});
</script>

<template>
  <div class="w-full">
    <div class="w-full">
      <RadioGroup v-model="selected">
        <div class="flex" :class="radioGroupStyle">
          <RadioGroupOption
            as="template"
            v-for="radio in radios"
            :key="radio.value"
            :value="radio"
            :disabled="radio.disabled || disabled"
            v-slot="{ checked }"
          >
            <div
              :class="cardStyle(checked, radio.disabled, radio.alreadySelected)"
              class="relative w-full flex cursor-pointer transition-all"
              @click="radio.disabled || disabled ? null : update(radio)"
            >
              <div class="flex w-full items-center gap-4" :class="cardAlignment">
                <input v-if="layout === 'radio'" type="radio" :checked="checked" />

                <div class="text-sm">
                  <RadioGroupLabel
                    as="p"
                    class="font-medium"
                    :class="[
                      labelStyle(checked, radio.disabled, radio.alreadySelected),
                      { 'text-center': isMobile || alignment === 'grid' }
                    ]"
                  >
                    {{ radio.label }}
                  </RadioGroupLabel>
                  <RadioGroupDescription
                    v-if="layout === 'box'"
                    as="span"
                    :class="descriptionStyle(checked, radio.disabled, radio.alreadySelected)"
                  >
                    <span> {{ radio.description }}</span>
                  </RadioGroupDescription>
                </div>

                <!-- Icon check -->
                <div
                  v-show="alignment === 'column' && layout === 'box'"
                  :class="['shrink-0 text-white', checked ? 'opacity-100' : 'opacity-0']"
                >
                  <img :src="getIcon" alt="" />
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
  </div>
</template>
