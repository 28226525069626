import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type RouteLocationRaw,
  Router,
  type RouteRecordRaw
} from "vue-router";
import { useStore } from "@/stores";
import { storeToRefs } from "pinia";
import { createToken, createUserByToken, getToken } from "@/utils/tokenUtils";
import { refreshToken } from "@/services/auth";

type Role = "admin" | "coach" | "participant" | "particulier";

const routes: Array<RouteRecordRaw> = [
  // Légales
  {
    path: "/",
    component: () => import("@/views/Main.vue"),
    children: [
      {
        path: "/legal-notice",
        name: "legal-notice",
        component: () => import("@/views/shared/LegalNotice.vue")
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("@/views/shared/PrivacyPolicy.vue")
      }
    ]
  },
  // Participants
  {
    path: "/first-login",
    name: "participant-first-login",
    component: () => import("@/views/participants/FirstLogin.vue"),
    meta: { requiresRole: ["participant", "particulier"] }
  },
  {
    path: "/email-confirmation",
    name: "participant-email-confirmation",
    component: () => import("@/views/participants/EmailConfirmation.vue"),
    meta: { requiresRole: ["participant", "particulier"] },
    props: (route: RouteLocationNormalized) => ({ email: route.query.email })
  },
  {
    path: "/",
    component: () => import("@/views/Main.vue"),
    meta: { requiresRole: "participant" },
    redirect: { name: "participant-register-sessions" },
    children: [
      {
        path: "dashboard",
        name: "participant-dashboard",
        redirect: { name: "participant-register-sessions" }
      },
      {
        path: "register-sessions",
        name: "participant-register-sessions",
        component: () => import("@/views/participants/SessionRegister.vue")
      },
      {
        path: "my-sessions",
        name: "participant-my-sessions",
        component: () => import("@/views/participants/MySessions.vue")
      },
      {
        path: "my-absences",
        name: "participant-my-absences",
        component: () => import("@/views/participants/MyAbsences.vue")
      },
      {
        path: "profile",
        name: "participant-profile",
        component: () => import("@/views/participants/Profile.vue")
      },
      // Managers
      {
        path: "/manager",
        name: "manager-manage",
        meta: { requiresRole: "participant", isManager: true },
        redirect: { name: "manager-manage-calendar" },
        children: [
          {
            path: "calendar",
            name: "manager-manage-calendar",
            component: () => import("@/views/managers/Calendar.vue")
          },
          {
            path: "statistics",
            name: "manager-manage-statistics",
            component: () => import("@/views/managers/Statistics.vue")
          },
          {
            path: "participants",
            name: "manager-manage-participants",
            component: () => import("@/views/managers/ParticipantManagement.vue")
          }
        ]
      }
    ]
  },
  // Particulier
  {
    path: "/particulier",
    name: "particulier",
    component: () => import("@/views/Main.vue"),
    redirect: { name: "particulier-dashboard" },
    meta: { requiresRole: "particulier" },
    children: [
      {
        path: "dashboard",
        name: "particulier-dashboard",
        component: () => import("@/views/particuliers/Dashboard.vue")
      },
      {
        path: "profile",
        name: "particulier-profile",
        component: () => import("@/views/particuliers/Profile.vue")
      }
    ]
  },
  // Admin
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/Main.vue"),
    redirect: { name: "admin-dashboard" },
    meta: { requiresRole: "admin" },
    children: [
      {
        path: "dashboard",
        name: "admin-dashboard",
        redirect: { name: "admin-manage-companies" }
      },
      {
        path: "companies",
        name: "admin-manage-companies",
        component: () => import("@/views/admins/CompaniesManagement.vue")
      },
      {
        path: "particuliers",
        name: "admin-manage-particuliers",
        component: () => import("@/views/admins/ParticuliersManagement.vue")
      },
      {
        path: "coaches",
        name: "admin-manage-coaches",
        component: () => import("@/views/admins/CoachsManagement.vue")
      },
      {
        path: "activities",
        name: "admin-manage-activities",
        component: () => import("@/views/admins/ActivitiesManagement.vue")
      },
      {
        path: "messages",
        name: "admin-manage-messages",
        component: () => import("@/views/admins/MessagesManagement.vue")
      },
      {
        path: "export",
        name: "admin-export",
        component: () => import("@/views/admins/Export.vue")
      },
      {
        path: "avis",
        name: "admin-avis",
        component: () => import("@/views/admins/AvisManagement.vue")
      },
      {
        path: "profile",
        name: "admin-profile",
        component: () => import("@/views/admins/Profile.vue")
      }
    ]
  },
  // Coach
  {
    path: "/coach",
    name: "coach",
    component: () => import("@/views/Main.vue"),
    redirect: { name: "coach-dashboard" },
    meta: { requiresRole: "coach" },
    children: [
      {
        path: "dashboard",
        name: "coach-dashboard",
        component: () => import("@/views/coachs/Dashboard.vue")
      },
      {
        path: "calendar",
        name: "coach-calendar",
        component: () => import("@/views/coachs/Calendar.vue"),
        props: (route: RouteLocationNormalized) => ({ date: route.query.date })
      },
      {
        path: "absences",
        name: "coach-absences",
        component: () => import("@/views/coachs/CoachIndispos.vue")
      },
      {
        path: "profile",
        name: "coach-profile",
        component: () => import("@/views/coachs/Profile.vue")
      }
    ]
  },
  // Not logged
  {
    path: "/",
    name: "not-logged",
    redirect: { name: "login" },
    children: [
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/shared/SignUp.vue"),
        props: (route: RouteLocationNormalized) => ({ companyCode: route.query.companyCode })
      },
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/shared/Login.vue"),
        props: (route: RouteLocationNormalized) => ({
          redirect: route.query.redirect,
          from: route.query.from
        })
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/shared/PasswordReset.vue")
      },
      {
        path: "/new-password",
        name: "new-password",
        component: () => import("@/views/shared/NewPassword.vue"),
        props: (route: RouteLocationNormalized) => ({ token: route.query.token })
      }
    ]
  }
];

/**
 * Récupère les routes qui ne nécessitent pas d'être connecté
 */
function routesNotLogged(): string[] {
  const [notLoggedRoute] = routes.filter((route: RouteRecordRaw) => route.name === "not-logged");
  const children = notLoggedRoute?.children?.map((child) => child.name);

  return children?.toString()?.split(",") ?? [];
}

/**
 * Vérifie si l'utilisateur a accès à la route
 * @param {Role} role - Le rôle de l'utilisateur
 * @param {string | string[]} requiresRole - Le(s) rôle(s) nécessaire pour accéder à la route
 * @returns {boolean} - true si l'utilisateur a accès à la route, false sinon
 */
function haveAccessToRoute(role: Role, requiresRole: string | string[]): boolean {
  if (Array.isArray(requiresRole)) return requiresRole.includes(role);
  return requiresRole === role;
}

/**
 * Vérifie si l'utilisateur est connecté
 * @param {RouteLocationNormalized} to - La route vers laquelle l'utilisateur veut aller
 * @returns {Promise<boolean>} - true si l'utilisateur est connecté, false sinon
 */
async function checkUserIsConnected(to: RouteLocationNormalized): Promise<boolean> {
  const token: string = getToken();

  if (token !== "") {
    createUserByToken(token);
    return true;
  }

  if (routesNotLogged().includes(to.name?.toString() || "")) return false;

  const { data, error } = await refreshToken();

  if (error || !data) return false;

  createToken(data.token);
  return true;
}

/**
 * Récupère la route du dashboard en fonction du rôle de l'utilisateur
 * @returns {RouteLocationRaw} - La route du dashboard
 */
function getDashboard(): RouteLocationRaw {
  const store = useStore();
  const { getRole } = storeToRefs(store.user);

  let route: RouteLocationRaw;
  switch (getRole.value) {
    case "participant":
      route = { name: "participant-dashboard" };
      break;
    case "particulier":
      route = { name: "particulier-dashboard" };
      break;
    case "coach":
      route = { name: "coach-dashboard" };
      break;
    case "admin":
      route = { name: "admin-dashboard" };
      break;
    default:
      route = { name: "participant-dashboard" };
      break;
  }
  return route;
}

/**
 * Redirige l'utilisateur vers son dashboard
 * @returns {Promise<void>}
 */
export async function goToDashboard(): Promise<void> {
  const route = getDashboard();
  if (route) await router.push(route);
}

const router: Router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const store = useStore();
    const { getEmail, getEmailConfirmation, getIsManager, getFirstLogin, getRole } = storeToRefs(
      store.user
    );
    const role: Role = getRole.value;

    // Si la route est accessible sans être connecté
    const needLogged = !routesNotLogged().includes(to.name?.toString() ?? "");

    // Si l'utilisateur n'est pas connecté et que la route nécessite d'être connecté
    const isConnected: boolean = await checkUserIsConnected(to);
    if (!isConnected && needLogged) {
      to.name
        ? next({ name: "login", query: { redirect: to.name.toString() } })
        : next({ name: "login" });
      return;
    }

    // Si l'utilisateur est connecté et que la route ne nécessite pas d'être connecté
    if (isConnected && !needLogged) {
      next(getDashboard());
      return;
    }

    if (to.meta.requiresRole === undefined && to.name !== undefined) {
      next();
      return;
    }

    // S'il n'y a pas de route spécifiée
    // Si l'utilisateur n'a pas accès à la route
    // Si l'utilisateur n'est pas un manager et qu'il essaie d'accéder à la page du manager
    // Si l'utilisateur a déjà confirmé son email et qu'il essaie d'accéder à la page de confirmation
    // Si l'utilisateur a déjà fait sa première connexion et qu'il essaie d'accéder à la page de première connexion
    if (
      !to.name ||
      !haveAccessToRoute(role, to.meta.requiresRole as string | string[]) ||
      (to.meta.isManager && !getIsManager.value) ||
      (getEmailConfirmation.value && to.name === "participant-email-confirmation") ||
      (!getFirstLogin.value && to.name === "participant-first-login")
    ) {
      next(getDashboard());
      return;
    }

    // Le participant/particulier doit confirmer son email
    if (
      role !== "admin" &&
      role !== "coach" &&
      !getEmailConfirmation.value &&
      to.name !== "participant-email-confirmation"
    ) {
      next({ name: "participant-email-confirmation", query: { email: getEmail.value } });
      return;
    }

    // Le participant/particulier doit faire sa première connexion
    if (
      role !== "admin" &&
      role !== "coach" &&
      getFirstLogin.value &&
      to.name !== "participant-first-login" &&
      getEmailConfirmation.value
    ) {
      next({ name: "participant-first-login" });
      return;
    }

    next();
  }
);

export default router;
