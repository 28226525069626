<script setup lang="ts">
import { computed, type PropType } from "vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

export interface PopoverItem {
  name: string;
  description: string;
  icon: {
    name: string;
    type: "bold" | "linear";
  };
  action: string;
}

export interface PopoverDescription {
  title: string;
  content: string;
}

const emits = defineEmits(["onclick"]);

const props = defineProps({
  title: {
    type: String,
    default: ""
  },
  position: {
    type: String as PropType<"left" | "center" | "right">
  },
  color: {
    type: String as PropType<
      | "primary"
      | "slate"
      | "gray"
      | "zinc"
      | "neutral"
      | "stone"
      | "red"
      | "orange"
      | "amber"
      | "yellow"
      | "lime"
      | "green"
      | "emerald"
      | "teal"
      | "cyan"
      | "sky"
      | "blue"
      | "indigo"
      | "violet"
      | "purple"
      | "fuchsia"
      | "pink"
      | "rose"
      | "white"
      | "primary"
    >,
    default: "primary"
  },
  items: {
    type: Array as PropType<PopoverItem[]>,
    default: () => []
  },
  description: {
    type: Object as PropType<PopoverDescription>,
    default: () => ({})
  }
});

function getColor(weight: string) {
  switch (props.color) {
    case "white":
      return `text-lc-primary`;
    case "primary":
      return `text-white`;
    default:
      return `text-${props.color}-${weight}`;
  }
}

function getBgColor(weight: string) {
  switch (props.color) {
    case "white":
      return `bg-white shadow`;
    case "primary":
      return `bg-lc-primary`;
    default:
      return `bg-${props.color}-${weight}`;
  }
}

function getIconActions(item: PopoverItem) {
  return new URL(
    `/src/assets/icons/iconsax/${item.icon.type}/${item.icon.name}.svg`,
    import.meta.url
  ).href;
}

const getPosition = computed(() => {
  switch (props.position) {
    case "left":
      return "left-0";
    case "center":
      return "left-1/2 -translate-x-1/2";
    case "right":
      return "right-0";
    default:
      return "left-0";
  }
});

const getIcon = computed(() => {
  let color: string;
  switch (props.color) {
    case "white":
      color = "primary";
      break;
    case "primary":
      color = "white";
      break;
    default:
      color = props.color;
      break;
  }

  return new URL(`/src/assets/icons/iconsax/bold/arrow-down-${color}.svg`, import.meta.url).href;
});
</script>

<template>
  <div class="w-fit">
    <Popover v-slot="{ open }" class="relative">
      <PopoverButton
        class="flex items-center rounded-md font-medium px-3 py-2 outline-none"
        :class="[getColor('600'), getBgColor('100')]"
      >
        <span>{{ title }}</span>
        <img
          :src="getIcon"
          class="ml-2 h-5 w-5 transition duration-200 ease-in-out"
          :class="open ? '-rotate-180' : ''"
        />
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel v-slot="{ close }" class="absolute w-fit z-10 mt-3" :class="getPosition">
          <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="w-max max-w-[400px] flex flex-col gap-4 bg-white p-4 outline-none">
              <a
                v-for="item in items"
                :key="item.name"
                class="flex items-center gap-4 rounded-lg transition duration-150 ease-in-out cursor-pointer"
                @click="emits('onclick', item.action), close()"
              >
                <div
                  class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg"
                  :class="getBgColor('100')"
                >
                  <img :src="getIconActions(item)" />
                </div>
                <div>
                  <p
                    class="text-sm font-medium"
                    :class="color === 'primary' ? 'text-lc-primary' : getColor('900')"
                  >
                    {{ item.name }}
                  </p>
                  <p
                    class="text-sm"
                    :class="color === 'primary' ? 'text-lc-secondary' : getColor('600')"
                  >
                    {{ item.description }}
                  </p>
                </div>
              </a>
            </div>
            <div class="px-4 py-2" :class="color === 'white' ? 'bg-gray-100' : getBgColor('50')">
              <a class="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out">
                <span class="flex items-center">
                  <span class="text-sm font-medium" :class="getColor('900')">{{
                    description.title
                  }}</span>
                </span>
                <span class="block text-sm" :class="getColor('600')">{{
                  description.content
                }}</span>
              </a>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>
