<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { Listbox, ListboxButton, ListboxOption as Options, ListboxOptions } from "@headlessui/vue";
import Star from "@/components/pages/participants/Avis/Star.vue";

const emits = defineEmits(["selectedData"]);

const props = defineProps({
  data: {
    type: Array,
    required: true
  },
  buttonLabel: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  ratings: {
    type: Boolean,
    default: false
  },
  selectAllHeader: {
    type: Boolean,
    default: false
  },
  defaultSelected: {
    type: Array,
    default: () => []
  }
});

const list = ref<any[]>([]);
const selectedData = ref<any[]>(props.defaultSelected);

function getSelectedData() {
  return selectedData.value.filter((d) => d.id !== undefined).map((i: any) => i.id);
}

function reset() {
  selectedData.value = [list.value[0]];
}

watch(
  () => props.data,
  () => {
    list.value = props.data;

    if (props.selectAllHeader) {
      list.value.unshift({ id: undefined, name: "Tout sélectionner" });
      if (props.defaultSelected.length === 0) reset();
    }
  },
  { immediate: true }
);

watch(
  () => selectedData.value,
  (newValue, oldValue) => {
    // Si la valeur n'a pas changé, on ne fait rien
    if (oldValue.length === newValue.length) return;

    // Si la valeur est vide, on remet la valeur par défaut
    if (newValue.length === 0 && props.selectAllHeader) {
      selectedData.value = [list.value[0]];
      return;
    }

    const selectAllClicked =
      newValue.find((i: any) => i.id === undefined) &&
      !oldValue.find((i: any) => i.id === undefined);

    // Si la valeur est "Tout sélectionner", on sélectionne tout
    if (selectAllClicked) {
      selectedData.value = [list.value[0]];
      return;
    }

    // Si la valeur est différente de "Tout sélectionner", on enlève "Tout sélectionner" de la liste
    selectedData.value = newValue.filter((i: any) => i.id !== undefined);

    emits("selectedData", getSelectedData());
  }
);

const listLength = computed(() => {
  return props.selectAllHeader ? list.value.length - 1 : list.value.length;
});

defineExpose({
  getSelectedData,
  reset
});
</script>

<template>
  <Listbox v-model="selectedData" multiple>
    <div class="relative">
      <ListboxButton
        class="relative cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-9 border border-gray-300"
      >
        {{ buttonLabel }}

        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <img src="@/assets/icons/iconsax/linear/arrow-separate-vertical.svg" alt="" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-50 mt-1 max-h-96 w-fit overflow-auto rounded-md bg-white shadow-lg"
        >
          <Options
            v-slot="{ active, selected }"
            v-for="(item, index) in list"
            :key="item.id"
            :value="item"
            as="template"
          >
            <li
              :class="[
                active ? 'text-blue-900 bg-blue-50' : 'text-black',
                { 'text-blue-900 bg-blue-100': selected },
                'cursor-pointer select-none relative py-[0.4rem] pl-10 pr-3 whitespace-nowrap'
              ]"
            >
              <span
                v-if="selected"
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"
              >
                <img src="@/assets/icons/iconsax/linear/tick-blue-600.svg" alt="" class="w-7 h-7" />
              </span>

              <span v-if="selectAllHeader && index == 0">
                {{ item.name }}
              </span>
              <div v-else-if="ratings" class="flex">
                <Star v-for="i in listLength" :key="i" :isSelected="i <= index" class="w-7" />
              </div>
              <span v-else>
                {{ item[label] ?? item.name }}
              </span>
            </li>
          </Options>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
