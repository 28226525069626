<script setup lang="ts">
import type { PropType } from "vue";
import type { User } from "@/interfaces/user.interface";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  TransitionRoot
} from "@headlessui/vue";

const emits = defineEmits(["update:modelValue", "selected"]);

defineProps({
  modelValue: {
    type: String as PropType<string | number>,
    default: ""
  },
  searchResult: {
    type: Array as PropType<User[]>,
    default: () => []
  },
  noResult: {
    type: String,
    default: "Aucun résultat"
  }
});
</script>

<template>
  <div class="w-full">
    <Combobox :value="modelValue">
      <div class="mt-1">
        <div
          class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md sm:text-sm"
        >
          <ComboboxInput
            class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 outline-none"
            placeholder="Rechercher un compte existant"
            @input="emits('update:modelValue', $event.target.value)"
          />
          <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
            <img src="@/assets/icons/iconsax/linear/arrow-down.svg" alt="Déroulé la liste" />
          </ComboboxButton>
        </div>

        <TransitionRoot
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div class="absolute w-full z-40 pb-2">
            <ComboboxOptions
              class="mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <div v-if="searchResult.length === 0" class="select-none py-2 pl-10 pr-4">
                <span class="block truncate">{{ noResult }}</span>
              </div>

              <ComboboxOption
                v-for="person in searchResult"
                as="template"
                :key="person.id"
                :value="person"
                v-slot="{ selected, active }"
              >
                <div
                  class="relative cursor-pointer select-none py-2 pl-10 pr-4"
                  :class="[active ? 'bg-gray-200' : 'text-gray-900']"
                  @click="emits('selected', person)"
                >
                  <span :class="['block truncate', selected ? 'font-medium' : 'font-normal']">
                    {{ `${person.firstname} ${person.lastname}` }}
                  </span>
                  <span
                    v-if="selected"
                    class="absolute inset-y-0 left-0 flex items-center pl-3"
                    :class="[active ? 'text-white' : 'text-teal-600']"
                  >
                    <img src="@/assets/icons/iconsax/linear/check.svg" alt="Est sélectionné" />
                  </span>
                </div>
              </ComboboxOption>
            </ComboboxOptions>
          </div>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>
