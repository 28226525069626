import { defineStore } from "pinia";
import { useUserStore } from "./modules/user";
import { useToastStore } from "./modules/notification";
import { useSessionStore } from "./modules/session";

export const useStore = defineStore("store", () => {
  const user = useUserStore();
  const toast = useToastStore();
  const session = useSessionStore();

  return { user, toast, session };
});
