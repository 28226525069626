<script setup lang="ts">
import { PropType, ref, watch } from "vue";
import type {
  Button,
  Checkbox,
  Color,
  Icon,
  Link,
  Pill,
  Toggle
} from "@/interfaces/components/PeTable.interface";

const emits = defineEmits(["getClick", "toggleValue"]);

const props = defineProps({
  data: {
    type: [String, Number, Boolean, Object, Array] as PropType<
      string | number | boolean | object | Array<any>
    >
  },
  button: {
    type: Object as PropType<Button>
  },
  pill: {
    type: Object as PropType<Pill>
  },
  icon: {
    type: Object as PropType<Icon>
  },
  toggle: {
    type: Object as PropType<Toggle>
  },
  checkbox: {
    type: Object as PropType<Checkbox>
  },
  link: {
    type: Object as PropType<Link>
  },
  color: {
    type: Object as PropType<Color>
  },
  condition: {
    type: Boolean
  },
  multipleCondition: {
    type: Boolean
  },
  breakpoint: {
    type: String,
    default: null
  },
  class_: {
    type: String,
    default: ""
  }
});

const toggleValue = ref<boolean>(!!props.toggle?.value);
const checkboxValue = ref<boolean>(!!props.checkbox?.value);

function getIcon() {
  if (!props.icon) return "";

  return new URL(
    `/src/assets/icons/iconsax/${props.icon.type}/${props.icon.name}.svg`,
    import.meta.url
  ).href;
}

function getButtonIcon() {
  if (!props.button?.icon) return "";

  return new URL(
    `/src/assets/icons/iconsax/${props.button.icon.type}/${props.button.icon.name}.svg`,
    import.meta.url
  ).href;
}

watch(toggleValue, (newValue) => {
  emits("toggleValue", props.data, newValue);
});

watch(checkboxValue, (newValue) => {
  emits("toggleValue", props.data, newValue);
});

watch(
  () => props.toggle,
  (newValue) => {
    toggleValue.value = !!newValue?.value;
  }
);

watch(
  () => props.checkbox,
  (newValue) => {
    checkboxValue.value = !!newValue?.value;
  }
);
</script>

<template>
  <td v-if="!condition || !multipleCondition"></td>
  <td v-else :class="['text-gray-500', breakpoint, class_]">
    <!-- ========== Pill ========== -->
    <pe-pill v-if="pill" :label="pill.label" :type="pill.type" />

    <!-- ========== Button ========== -->
    <button
      v-else-if="button"
      @click="emits('getClick', data, button.parameter)"
      :class="['min-w-6 flex items-center gap-2', button.class_]"
    >
      {{ button.name ?? data }}
      <img v-if="button.icon" :src="getButtonIcon()" :alt="button.icon.name" class="" />
    </button>

    <!-- ========== Icon ========== -->
    <div v-else-if="icon" :class="[icon.size, class_]">
      <img v-if="icon" :src="getIcon()" :alt="icon.name" :class="[icon.size, icon.class_]" />
    </div>

    <!-- ========== Toggle ========== -->
    <pe-toggle
      v-else-if="toggle"
      v-model="toggleValue"
      :before="toggle.before"
      :after="toggle.after"
    />

    <!-- ========== Checkbox ========== -->
    <input
      v-else-if="checkbox"
      type="checkbox"
      :checked="checkboxValue"
      :disabled="checkbox.disabled"
      :class="['daisy-checkbox checkbox-lc-primary', checkbox.class_]"
      @change="checkboxValue = !checkboxValue"
    />

    <!-- ========== Link ========== -->
    <a
      v-else-if="link"
      :href="link.url"
      :target="link.blank ? '_blank' : ''"
      :class="['text-blue-700 lg:hover:underline', class_]"
    >
      {{ link.name ?? data }}
    </a>

    <!-- ========== Color ========== -->
    <div
      v-else-if="color"
      :class="`m-0 rounded-${color.rounded}`"
      :style="`
        width: ${color.size ?? '1rem'};
        height: ${color.size ?? '1rem'};
        background-color: ${color.hex ?? data};
      `"
    ></div>

    <!-- ========== Data ========== -->
    <span :class="[class_, 'whitespace-nowrap']" v-else>
      {{ data }}
    </span>
  </td>
</template>
