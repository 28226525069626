<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, type PropType, ref } from "vue";
import loadingWhiteAnimation from "@/assets/lottiefiles/loading-white.json";
import loadingAnimation from "@/assets/lottiefiles/loading.json";
import type { IconButton } from "@/interfaces/components/PeButton.interface";
import { useStore } from "@/stores";
import { storeToRefs } from "pinia";

type Size = "xs" | "sm" | "base" | "lg" | "xl";

const emits = defineEmits(["click"]);

const props = defineProps({
  type: {
    type: String as PropType<"primary" | "secondary" | "white" | "emerald" | "red" | "register">,
    default: ""
  },
  buttontype: {
    type: String
  },
  size: {
    type: String as PropType<Size>,
    default: "base"
  },
  mobileSize: {
    type: String as PropType<Size>,
    default: undefined
  },
  label: {
    type: String,
    default: ""
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  custom: {
    type: String,
    default: ""
  },
  icon: {
    type: Object as PropType<IconButton>,
    default: null
  }
});

const store = useStore();
const { isMobile } = storeToRefs(store.user);

const mobile = ref<boolean>(false);
const currentSize = computed<Size>(() => (mobile.value ? props.mobileSize : props.size) ?? "base");

onMounted(() => {
  if (props.mobileSize) {
    onResize();
    window.addEventListener("resize", onResize);
    onBeforeUnmount(() => window.removeEventListener("resize", onResize));
  }
});

async function onResize() {
  mobile.value = isMobile.value;
}

function getIcon(): string | undefined {
  const { icon } = props;
  if (!icon) return;

  const { type, name } = icon;
  if (type === "linear" || type === "bold") {
    return new URL(`/src/assets/icons/iconsax/${type}/${name}.svg`, import.meta.url).href;
  } else if (type === "lottie") {
    return new URL(`/src/assets/lottiefiles/${name}.json`).href;
  }
}

const getRoundedBySize = computed(() => {
  const sizes = {
    xs: "rounded",
    sm: "rounded-md",
    base: "rounded-md",
    lg: "rounded-md",
    xl: "rounded-md"
  };
  return sizes[currentSize.value as keyof typeof sizes];
});

const getIconSizeBySize = computed(() => {
  const sizes = {
    xs: "h-4 w-4",
    sm: "h-4 w-4",
    base: "h-5 w-5",
    lg: "h-5 w-5",
    xl: "h-5 w-5"
  };
  return sizes[currentSize.value as keyof typeof sizes];
});

const getPaddingXBySize = computed(() => {
  const sizes = {
    xs: "px-[11px]",
    sm: "px-[13px]",
    base: "px-[17px]",
    lg: "px-[17px]",
    xl: "px-[25px]"
  };
  return sizes[currentSize.value as keyof typeof sizes];
});

const getStyleTextBySize = computed(() => {
  const sizes = {
    xs: "text-xs leading-4 " + (props.type === "white" ? "py-[6px]" : "py-[7px]"),
    sm: "text-sm leading-4 " + (props.type === "white" ? "py-[8px]" : "py-[9px]"),
    base: "text-sm leading-5 " + (props.type === "white" ? "py-[8px]" : "py-[9px]"),
    lg: "text-base leading-6 " + (props.type === "white" ? "py-[8px]" : "py-[9px]"),
    xl: "text-base leading-6 " + (props.type === "white" ? " py-[12px]" : "py-[13px]")
  };
  return sizes[currentSize.value as keyof typeof sizes];
});

const getType = computed(() => {
  const types = {
    primary: "bg-lc-primary lg:hover:bg-lc-primaryHover outline-lc-secondary text-white",
    secondary: "bg-lc-secondary lg:hover:bg-lc-secondaryHover outline-lc-secondary text-white",
    white:
      "border border-gray-300 bg-white lg:hover:bg-gray-50 outline-lc-secondary text-lc-gray-blackCoral",
    emerald: "bg-emerald-600 lg:hover:bg-emerald-700 text-white",
    red: "bg-red-600 lg:hover:bg-red-700 text-white",
    register: "bg-green-700 lg:hover:bg-red-600 text-white"
  };
  return types[props.type as keyof typeof types] || "";
});
</script>

<template>
  <button
    :type="buttontype ? 'button' : 'submit'"
    class="disabled:bg-gray-500 disabled:cursor-not-allowed flex items-center justify-center transition-colors duration-100 ease-out focus:outline-none"
    :class="[
      getType,
      getRoundedBySize,
      custom,
      isLoading ? ' cursor-wait relative' : 'h-fit cursor-pointer'
    ]"
    :disabled="disabled"
    @click="isLoading || emits('click')"
  >
    <div v-show="isLoading" class="absolute h-full">
      <Vue3Lottie
        :animationData="type === 'white' ? loadingAnimation : loadingWhiteAnimation"
        class="h-full"
      />
    </div>
    <div
      class="flex items-center h-full w-fit"
      :class="[
        icon?.position === 'leading' ? 'flex-row' : 'flex-row-reverse ',
        getPaddingXBySize,
        { 'gap-2': label }
      ]"
    >
      <img
        v-if="icon && (icon.type === 'linear' || icon.type === 'bold')"
        :src="getIcon()"
        :alt="icon?.name || 'btn icon'"
        :class="getIconSizeBySize"
      />
      <p
        class="whitespace-nowrap font-medium"
        :class="[getStyleTextBySize, isLoading ? '-z-10' : '']"
      >
        {{ label }}
      </p>
    </div>
  </button>
</template>
