<script setup lang="ts">
import type { PropType } from "vue";
import { Tab, TabGroup, TabList } from "@headlessui/vue";

export interface Tabs {
  name: string;
  emit: {
    table?: string;
    settings: string;
  };
}

const emits = defineEmits(["getEmitedCategory"]);

defineProps({
  categories: {
    type: Array as PropType<Tabs[]>,
    default: () => []
  }
});
</script>

<template>
  <TabGroup>
    <TabList class="flex flex-row gap-1 h-fit w-fit bg-gray-200 rounded-md p-1">
      <Tab v-for="category in categories" as="template" :key="category.name" v-slot="{ selected }">
        <button
          class="inline-flex px-3 py-2 items-center rounded-md text-sm leading-5 h-fit w-fit text-lc-gray-arsenic outline-none"
          :class="[selected ? 'bg-white' : 'bg-none lg:hover:bg-gray-300']"
          @click="emits('getEmitedCategory', category.emit.settings)"
        >
          {{ category.name }}
        </button>
      </Tab>
    </TabList>
  </TabGroup>
</template>
