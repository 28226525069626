<script setup lang="ts">
import { type PropType, ref } from "vue";

const emits = defineEmits(["files-dropped", "deleteError", "remove-file"]);

const props = defineProps({
  nameInput: {
    type: String,
    default: "upload-file"
  },
  mimes: {
    type: Array as PropType<string[]>
  },
  maxSize: {
    type: String,
    default: "5"
  },
  label: {
    type: String,
    default: ""
  },
  error: {
    type: String,
    default: ""
  }
});

const file = ref<File[]>([] as File[]);
const uploadState = ref<"none" | "success">("none");
const stateError = ref<string | undefined>(undefined);
const createObjectURL = window.URL.createObjectURL;

function uploadFile(event: any) {
  file.value = event.target.files;
  upload(event);
}

function dragFile(event: any) {
  stateError.value = undefined;

  if (!props.mimes?.includes(event.dataTransfer.files[0].type)) {
    stateError.value = "Le type de fichier n'est pas autorisé";
    return;
  }

  file.value = event.dataTransfer.files;
  upload(event);
}

function upload(event: any) {
  emits("files-dropped", file.value, event);
  uploadState.value = "success";
}

function removeFile() {
  file.value = [];
  uploadState.value = "none";
  emits("remove-file", new File(["foo"], "foo.png", { type: "logo/png" }));
  emits("deleteError");
}

// Get all extensions allowed
function allowedExtensions() {
  let allowedExtensions = ["Toutes extensions"];
  if (props.mimes) {
    allowedExtensions = props.mimes.map((mime: string) => {
      return mime;
    });
    return allowedExtensions;
  }
}

defineExpose({ removeFile });
</script>

<template>
  <div style="border-style: solid" @dragover.prevent @drop.prevent>
    <input
      :id="nameInput"
      :name="nameInput"
      type="file"
      :accept="mimes?.join(',')"
      class="hidden"
      @change="uploadFile"
      @input="error ? emits('deleteError') : ''"
    />

    <label
      @drop="dragFile"
      :for="nameInput"
      :id="nameInput + '-div'"
      class="block rounded-md border-2 border-dashed bg-white py-6 px-4 cursor-pointer relative"
      :class="[error ? 'border-red-300' : 'border-gray-300']"
    >
      <img
        v-if="uploadState === 'success'"
        src="@/assets/icons/iconsax/linear/close-gray-arsenic.svg"
        alt="Supprimer le fichier"
        class="absolute top-2 right-2 w-6 h-6 cursor-pointer"
        @click.prevent="removeFile"
      />

      <div class="flex flex-col gap-1 items-center justify-center">
        <template v-if="uploadState === 'none'">
          <img
            src="@/assets/icons/iconsax/linear/document-text-gray-quickSilver.svg"
            alt="Télécharger, glisser ou déposer mon certificat médical"
            aria-hidden="true"
            class="w-12 h-12 stroke-lc-gray-quickSilver select-none"
          />
          <p class="text-sm font-medium text-lc-primary text-center">
            <span>Télécharger</span>
            <span class="hidden lg:inline-block">, glisser ou déposer</span>
            <span>{{ ` ${label}` }}</span>
          </p>
          <p class="text-xs leading-4 text-lc-gray-dimGray text-center">
            <template v-for="extension in allowedExtensions()" :key="extension">
              {{ extension.toUpperCase() }},
            </template>
            jusqu'à {{ maxSize }} Mo
          </p>
        </template>
        <template v-else>
          <img
            :src="createObjectURL(file[0])"
            alt="Fichier téléchargé"
            aria-hidden="true"
            class="h-12 select-none"
          />
          <ul class="text-sm font-medium text-lc-gray-blackCoral">
            <li>{{ file?.[0]?.name }}</li>
          </ul>
        </template>
      </div>
    </label>

    <div
      v-if="error || stateError"
      class="inline-flex text-ellipsis text-sm font-medium text-red-600"
    >
      {{ error }} {{ stateError }}
    </div>
  </div>
</template>
