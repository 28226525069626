<script setup lang="ts">
import { onMounted, type PropType, ref } from "vue";

import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export interface Dataset {
  label: string;
  data: number[];
  barThickness?: number;
  backgroundColor?: string[];
  borderColor?: string[];
  hoverOffset?: number;
  radius?: number;
  borderWidth?: number;
  borderRadius?: number;
  fill?: boolean;
}

type Weight =
  | "normal"
  | "bold"
  | "bolder"
  | "lighter"
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

type PointStyle =
  | "circle"
  | "cross"
  | "crossRot"
  | "dash"
  | "line"
  | "rect"
  | "rectRounded"
  | "rectRot"
  | "star"
  | "triangle"
  | false;

const emits = defineEmits(["onclick"]);

const chart = ref(undefined as unknown as Chart);
const delayed = ref(false);

const props = defineProps({
  chartType: {
    type: String as PropType<
      "bar" | "bubble" | "doughnut" | "pie" | "line" | "polarArea" | "radar" | "scatter"
    >,
    required: true
  },
  width: {
    type: String
  },
  height: {
    type: String
  },
  responsive: {
    type: Boolean,
    default: true
  },
  maintainAspectRatio: {
    type: Boolean,
    default: false
  },
  indexAxis: {
    type: String as PropType<"x" | "y">,
    default: undefined
  },
  mode: {
    type: String as PropType<"index" | "nearest" | "point" | "x" | "y">,
    default: "index"
  },
  intersect: {
    type: Boolean,
    default: true
  },
  // ========== Scales ==========
  // X axis
  xBeginAtZero: {
    type: Boolean,
    default: true
  },
  xAlignToPixels: {
    type: Boolean,
    default: false
  },
  xBackgroundColor: {
    type: String,
    default: undefined
  },
  xBorder: {
    type: Boolean,
    default: true
  },
  xBorderColor: {
    type: String,
    default: undefined
  },
  xBorderWidth: {
    type: Number,
    default: 1
  },
  xBorderDash: {
    type: Array as PropType<number[]>,
    default: () => []
  },
  xBorderDashOffset: {
    type: Number,
    default: 0
  },
  xBorderZ: {
    type: Number,
    default: 0
  },
  xDisplay: {
    type: Boolean as PropType<boolean | "auto">,
    default: true
  },
  xMin: {
    type: Number,
    default: undefined
  },
  xMax: {
    type: Number,
    default: undefined
  },
  xReverse: {
    type: Boolean,
    default: false
  },
  xStacked: {
    type: Boolean,
    default: false
  },
  xSuggestedMax: {
    type: Number,
    default: undefined
  },
  xSuggestedMin: {
    type: Number,
    default: undefined
  },
  xTicksBackdropColor: {
    type: String,
    default: "rgba(255, 255, 255, 0.75)"
  },
  xTicksBackdropPadding: {
    type: Number,
    default: 2
  },
  xTicksDisplay: {
    type: Boolean,
    default: true
  },
  xTicksColor: {
    type: String,
    default: undefined
  },
  xTicksFontFamily: {
    type: String,
    default: undefined
  },
  xTicksFontSize: {
    type: Number,
    default: 12
  },
  xTicksFontStyle: {
    type: String as PropType<"normal" | "italic" | "oblique">,
    default: undefined
  },
  xTicksFontWeight: {
    type: String as PropType<Weight>,
    default: undefined
  },
  xTicksFontLineHeight: {
    type: Number,
    default: 1.2
  },
  xTicksPadding: {
    type: Number,
    default: 3
  },
  xTicksShowLabelBackdrop: {
    type: Boolean,
    default: undefined
  },
  xTicksTextStrokeColor: {
    type: String,
    default: undefined
  },
  xTicksTextStrokeWidth: {
    type: Number,
    default: 0
  },
  xTicksZ: {
    type: Number,
    default: 0
  },
  xWeight: {
    type: Number,
    default: 0
  },
  // Y axis
  yBeginAtZero: {
    type: Boolean,
    default: true
  },
  yAlignToPixels: {
    type: Boolean,
    default: false
  },
  yBackgroundColor: {
    type: String,
    default: undefined
  },
  yBorder: {
    type: Boolean,
    default: true
  },
  yBorderColor: {
    type: String,
    default: undefined
  },
  yBorderWidth: {
    type: Number,
    default: 1
  },
  yBorderDash: {
    type: Array as PropType<number[]>,
    default: () => []
  },
  yBorderDashOffset: {
    type: Number,
    default: 0
  },
  yBorderZ: {
    type: Number,
    default: 0
  },
  yDisplay: {
    type: Boolean as PropType<boolean | "auto">,
    default: true
  },
  yMin: {
    type: Number,
    default: undefined
  },
  yMax: {
    type: Number,
    default: undefined
  },
  yReverse: {
    type: Boolean,
    default: false
  },
  yStacked: {
    type: Boolean,
    default: false
  },
  ySuggestedMax: {
    type: Number,
    default: undefined
  },
  ySuggestedMin: {
    type: Number,
    default: undefined
  },
  yTicksBackdropColor: {
    type: String,
    default: "rgba(255, 255, 255, 0.75)"
  },
  yTicksBackdropPadding: {
    type: Number,
    default: 2
  },
  yTicksDisplay: {
    type: Boolean,
    default: true
  },
  yTicksColor: {
    type: String,
    default: undefined
  },
  yTicksFontFamily: {
    type: String,
    default: undefined
  },
  yTicksFontSize: {
    type: Number,
    default: 12
  },
  yTicksFontStyle: {
    type: String as PropType<"normal" | "italic" | "oblique">,
    default: undefined
  },
  yTicksFontWeight: {
    type: String as PropType<Weight>,
    default: undefined
  },
  yTicksFontLineHeight: {
    type: Number,
    default: 1.2
  },
  yTicksPadding: {
    type: Number,
    default: 3
  },
  yTicksShowLabelBackdrop: {
    type: Boolean,
    default: undefined
  },
  yTicksTextStrokeColor: {
    type: String,
    default: undefined
  },
  yTicksTextStrokeWidth: {
    type: Number,
    default: 0
  },
  yTicksZ: {
    type: Number,
    default: 0
  },
  yWeight: {
    type: Number,
    default: 0
  },
  // ========== Data ==========
  labels: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  datasets: {
    type: Object as PropType<Dataset[]>,
    required: true
  },
  // ========== Title ==========
  title: {
    type: String,
    default: "Chart"
  },
  titleDisplay: {
    type: Boolean,
    default: true
  },
  titlePosition: {
    type: String as PropType<"top" | "bottom" | "left" | "right">,
    default: undefined
  },
  titleAlign: {
    type: String as PropType<"start" | "center" | "end">,
    default: undefined
  },
  titleSize: {
    type: Number,
    default: 12
  },
  titleWeight: {
    type: String as PropType<Weight>,
    default: undefined
  },
  titleLineHeight: {
    type: Number,
    default: 1.2
  },
  titlePaddingTop: {
    type: Number,
    default: 10
  },
  titlePaddingBottom: {
    type: Number,
    default: 10
  },
  // ========== Legend ==========
  legendDisplay: {
    type: Boolean,
    default: true
  },
  legendPosition: {
    type: String as PropType<"top" | "bottom" | "left" | "right" | "chartArea">,
    default: "top"
  },
  legendAlign: {
    type: String as PropType<"start" | "center" | "end">,
    default: "center"
  },
  legendMaxHeight: {
    type: Number,
    default: undefined
  },
  legendMaxWidth: {
    type: Number,
    default: undefined
  },
  legendFullSize: {
    type: Boolean,
    default: true
  },
  legendReverse: {
    type: Boolean,
    default: false
  },
  // Right to left
  legendRtl: {
    type: Boolean,
    default: undefined
  },
  legendTextDirection: {
    type: String as PropType<"ltr" | "rtl">,
    default: undefined
  },
  // Legend Label
  legendLabelUsePointStyle: {
    type: Boolean,
    default: false
  },
  legendLabelPointStyle: {
    type: String as PropType<PointStyle>,
    default: "circle"
  },
  legendLabelBoxWidth: {
    type: Number,
    default: 40
  },
  legendLabelBoxHeight: {
    type: Number,
    default: undefined
  },
  legendLabelColor: {
    type: String,
    default: undefined
  },
  legendLabelFontFamily: {
    type: String,
    default: undefined
  },
  legendLabelFontSize: {
    type: Number,
    default: 12
  },
  legendLabelFontStyle: {
    type: String as PropType<"normal" | "italic" | "oblique">,
    default: "normal"
  },
  legendLabelWeight: {
    type: String as PropType<Weight>,
    default: undefined
  },
  legendLabelLineHeight: {
    type: Number,
    default: 1.2
  },
  legendLabelPadding: {
    type: Number,
    default: 10
  },
  legendLabelTextAlign: {
    type: String as PropType<"left" | "center" | "right" | undefined>,
    default: "center"
  },
  // Legend Title
  legendTitleDisplay: {
    type: Boolean,
    default: false
  },
  legendTitle: {
    type: String,
    default: undefined
  },
  legendTitleColor: {
    type: String,
    default: undefined
  },
  legendTitleFontFamily: {
    type: String,
    default: undefined
  },
  legendTitleFontSize: {
    type: Number,
    default: 12
  },
  legendTitleFontStyle: {
    type: String as PropType<"normal" | "italic" | "oblique">,
    default: "normal"
  },
  legendTitleWeight: {
    type: String as PropType<Weight>,
    default: undefined
  },
  legendTitleLineHeight: {
    type: Number,
    default: 1.2
  },
  legendTitlePadding: {
    type: Number,
    default: 10
  },
  // ========== Element ==========
  // Point
  pointRadius: {
    type: Number,
    default: 3
  },
  pointStyle: {
    type: String as PropType<PointStyle>,
    default: "circle"
  },
  pointRotation: {
    type: Number,
    default: 0
  },
  pointBackgroundColor: {
    type: String,
    default: undefined
  },
  pointBorderWidth: {
    type: Number,
    default: 1
  },
  pointBorderColor: {
    type: String,
    default: undefined
  },
  pointHitRadius: {
    type: Number,
    default: 1
  },
  pointHoverRadius: {
    type: Number,
    default: 4
  },
  pointHoverBorderWidth: {
    type: Number,
    default: 1
  },
  // Line
  lineTension: {
    type: Number,
    default: 0
  },
  lineBackgroundColor: {
    type: String,
    default: undefined
  },
  lineBorderColor: {
    type: String,
    default: undefined
  },
  lineBorderWidth: {
    type: Number,
    default: 3
  },
  lineBorderCapStyle: {
    type: String as PropType<"butt" | "round" | "square">,
    default: "butt"
  },
  lineBorderDash: {
    type: Array as PropType<number[]>,
    default: undefined
  },
  lineBorderDashOffset: {
    type: Number,
    default: 0.0
  },
  lineBorderJoinStyle: {
    type: String as PropType<"bevel" | "round" | "miter">,
    default: "miter"
  },
  lineCapBezierPoints: {
    type: Boolean,
    default: true
  },
  lineCubicInterpolationMode: {
    type: String as PropType<"default" | "monotone">,
    default: "default"
  },
  // Bar
  barBackgroundColor: {
    type: String,
    default: undefined
  },
  barBorderWidth: {
    type: Number,
    default: 0
  },
  barBorderColor: {
    type: String,
    default: undefined
  },
  barBorderSkipped: {
    type: String as PropType<
      "start" | "end" | "middle" | "bottom" | "left" | "top" | "right" | false
    >,
    default: "start"
  },
  barRadius: {
    type: Number,
    default: 0
  },
  barInflateAmount: {
    type: [Number, String] as PropType<number | "auto">,
    default: "auto"
  },
  // Arc
  arcBackgroundColor: {
    type: String,
    default: undefined
  },
  arcBorderAlign: {
    type: String as PropType<"center" | "inner">,
    default: "center"
  },
  arcBorderColor: {
    type: String,
    default: "#fff"
  },
  arcBorderJoinStyle: {
    type: String as PropType<"bevel" | "round" | "miter">,
    default: "bevel"
  },
  arcBorderWidth: {
    type: Number,
    default: 2
  },
  arcCircular: {
    type: Boolean,
    default: true
  }
});

onMounted(() => {
  new Chart(chart.value, {
    // ==================== Type ====================
    type: props.chartType,
    // ==================== Data ====================
    data: {
      labels: props.labels,
      datasets: props.datasets
    },
    // ==================== Options ====================
    options: {
      responsive: props.responsive,
      maintainAspectRatio: props.maintainAspectRatio,
      indexAxis: props.indexAxis,
      // ========== Scales ==========
      scales: {
        x: {
          beginAtZero: props.xBeginAtZero,
          alignToPixels: props.xAlignToPixels,
          backgroundColor: props.xBackgroundColor,
          border: {
            display: props.xBorder,
            color: props.xBorderColor,
            width: props.xBorderWidth,
            dash: props.xBorderDash,
            dashOffset: props.xBorderDashOffset,
            z: props.xBorderZ
          },
          display: props.xDisplay,
          min: props.xMin,
          max: props.xMax,
          reverse: props.xReverse,
          stacked: props.xStacked,
          suggestedMax: props.xSuggestedMax,
          suggestedMin: props.xSuggestedMin,
          ticks: {
            backdropColor: props.xTicksBackdropColor,
            backdropPadding: props.xTicksBackdropPadding,
            display: props.xTicksDisplay,
            color: props.xTicksColor,
            font: {
              family: props.xTicksFontFamily,
              size: props.xTicksFontSize,
              style: props.xTicksFontStyle,
              weight: props.xTicksFontWeight,
              lineHeight: props.xTicksFontLineHeight
            },
            padding: props.xTicksPadding,
            showLabelBackdrop: props.xTicksShowLabelBackdrop,
            textStrokeColor: props.xTicksTextStrokeColor,
            textStrokeWidth: props.xTicksTextStrokeWidth,
            z: props.xTicksZ
          },
          weight: props.xWeight
        },
        y: {
          beginAtZero: props.yBeginAtZero,
          alignToPixels: props.yAlignToPixels,
          backgroundColor: props.yBackgroundColor,
          border: {
            display: props.yBorder,
            color: props.yBorderColor,
            width: props.yBorderWidth,
            dash: props.yBorderDash,
            dashOffset: props.yBorderDashOffset,
            z: props.yBorderZ
          },
          display: props.yDisplay,
          min: props.yMin,
          max: props.yMax,
          reverse: props.yReverse,
          stacked: props.yStacked,
          suggestedMax: props.ySuggestedMax,
          suggestedMin: props.ySuggestedMin,
          ticks: {
            backdropColor: props.yTicksBackdropColor,
            backdropPadding: props.yTicksBackdropPadding,
            display: props.yTicksDisplay,
            color: props.yTicksColor,
            font: {
              family: props.yTicksFontFamily,
              size: props.yTicksFontSize,
              style: props.yTicksFontStyle,
              weight: props.yTicksFontWeight,
              lineHeight: props.yTicksFontLineHeight
            },
            padding: props.yTicksPadding,
            showLabelBackdrop: props.yTicksShowLabelBackdrop,
            textStrokeColor: props.yTicksTextStrokeColor,
            textStrokeWidth: props.yTicksTextStrokeWidth,
            z: props.yTicksZ
          },
          weight: props.xWeight
        }
      },
      // ========== Elements ==========
      elements: {
        point: {
          radius: props.pointRadius,
          pointStyle: props.pointStyle,
          rotation: props.pointRotation,
          backgroundColor: props.pointBackgroundColor,
          borderWidth: props.pointBorderWidth,
          borderColor: props.pointBorderColor,
          hitRadius: props.pointHitRadius,
          hoverRadius: props.pointHoverRadius,
          hoverBorderWidth: props.pointHoverBorderWidth
        },
        line: {
          tension: props.lineTension,
          backgroundColor: props.lineBackgroundColor,
          borderColor: props.lineBorderColor,
          borderWidth: props.lineBorderWidth,
          borderCapStyle: props.lineBorderCapStyle,
          borderDash: props.lineBorderDash,
          borderDashOffset: props.lineBorderDashOffset,
          borderJoinStyle: props.lineBorderJoinStyle,
          capBezierPoints: props.lineCapBezierPoints,
          cubicInterpolationMode: props.lineCubicInterpolationMode
        },
        bar: {
          backgroundColor: props.barBackgroundColor,
          borderWidth: props.barBorderWidth,
          borderColor: props.barBorderColor,
          borderSkipped: props.barBorderSkipped,
          borderRadius: props.barRadius,
          inflateAmount: props.barInflateAmount
        },
        arc: {
          backgroundColor: props.arcBackgroundColor,
          borderAlign: props.arcBorderAlign,
          borderColor: props.arcBorderColor,
          borderJoinStyle: props.arcBorderJoinStyle,
          borderWidth: props.arcBorderWidth,
          circular: props.arcCircular
        }
      },
      // ========== Plugins ==========
      plugins: {
        // ========== Title ==========
        title: {
          display: props.titleDisplay,
          position: props.titlePosition,
          align: props.titleAlign,
          text: props.title,
          font: {
            size: props.titleSize,
            weight: props.titleWeight,
            lineHeight: props.titleLineHeight
          },
          padding: {
            top: props.titlePaddingTop,
            bottom: props.titlePaddingBottom
          }
        },
        // ========== Legend ==========
        legend: {
          display: props.legendDisplay,
          position: props.legendPosition,
          align: props.legendAlign,
          maxHeight: props.legendMaxHeight,
          maxWidth: props.legendMaxWidth,
          fullSize: props.legendFullSize,
          reverse: props.legendReverse,
          rtl: props.legendRtl,
          textDirection: props.legendTextDirection,
          labels: {
            usePointStyle: props.legendLabelUsePointStyle,
            pointStyle: props.legendLabelPointStyle,
            boxWidth: props.legendLabelBoxWidth,
            boxHeight: props.legendLabelBoxHeight,
            color: props.legendLabelColor,
            font: {
              family: props.legendLabelFontFamily,
              size: props.legendLabelFontSize,
              style: props.legendLabelFontStyle,
              weight: props.legendLabelWeight,
              lineHeight: props.legendLabelLineHeight
            },
            padding: props.legendLabelPadding,
            textAlign: props.legendLabelTextAlign
          },
          title: {
            display: props.legendTitleDisplay,
            text: props.legendTitle,
            color: props.legendTitleColor,
            font: {
              family: props.legendTitleFontFamily,
              size: props.legendTitleFontSize,
              style: props.legendTitleFontStyle,
              weight: props.legendTitleWeight,
              lineHeight: props.legendTitleLineHeight
            },
            padding: props.legendTitlePadding
          }
        },
        // ========== Tooltip ==========
        tooltip: {
          mode: props.mode,
          intersect: props.intersect
        }
      },
      // ========== Hover ==========
      hover: {
        mode: props.mode,
        intersect: props.intersect
      },
      // ========== Event ==========
      onClick: (event, chartElement) => {
        emits("onclick", chartElement[0].index);
      },
      // ==================== Animation ====================
      animation: {
        onComplete: () => {
          delayed.value = true;
        },
        delay: (context) => {
          let delay = 0;
          if (context.type === "data" && context.mode === "default" && !delayed.value) {
            delay = context.dataIndex * 10 + context.datasetIndex * 10;
          }
          return delay;
        }
      }
    }
  });
});
</script>

<template>
  <canvas id="chart" ref="chart" :height="height" :width="width"></canvas>
</template>
