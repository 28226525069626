import { defineStore } from "pinia";

export const useSessionStore = defineStore("session", {
  state: () => ({
    sessions: []
  }),
  getters: {
    getSessions(state: any): Array<any> {
      return state.sessions;
    }
  }
});

export default useSessionStore;
