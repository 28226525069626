import axios, { AxiosInstance } from "axios";
import { createTokenCookie, deleteToken, getToken } from "@/utils/tokenUtils";
import router from "@/router/index";
import { RouteRecordName } from "vue-router";

function transformResponse(response: string) {
  if (response === "Forbidden") {
    deleteToken();
    return response;
  }

  try {
    if (!response) return null;
    return JSON.parse(response);
  } catch (e) {
    return response;
  }
}

async function checkToken(): Promise<string> {
  let token: string = getToken();
  if (token !== "") return token;

  axios.defaults.withCredentials = true;
  const routeName: RouteRecordName | undefined = router?.currentRoute?.value?.matched?.[0]?.name;

  if (routeName === "not-logged") return token;

  try {
    const { data } = await axios.post(`${import.meta.env.VITE_APP_API_URL}auth/refresh`);
    token = data.token;
    createTokenCookie(token);
  } catch (e) {
    if (routeName !== "not-logged") await router.push("/login");
  }

  return token;
}

export class AxiosSingleton {
  private static instance: AxiosSingleton;
  private readonly axiosInstance: AxiosInstance;

  private constructor() {
    this.axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json"
      },
      transformResponse: [transformResponse]
    });
  }

  public static getInstance(): AxiosSingleton {
    if (!AxiosSingleton.instance) {
      AxiosSingleton.instance = new AxiosSingleton();
    }

    return AxiosSingleton.instance;
  }

  public async getAxiosInstance(): Promise<AxiosInstance> {
    const token: string = await checkToken();
    this.axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`;
    return this.axiosInstance;
  }
}
