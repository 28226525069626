import { DBToken } from "@/interfaces/jwtToken.interface";

export class IndexedDatabase {
  private db: IDBDatabase | null = null;

  constructor() {
    this.init();
  }

  private async init(): Promise<void> {
    return new Promise((resolve, reject): void => {
      const request: IDBOpenDBRequest = indexedDB.open("lioncoach", 1);

      request.onerror = (): void => {
        reject(request.error);
      };

      request.onsuccess = (): void => {
        this.db = request.result;
        resolve();
      };

      request.onupgradeneeded = (): void => {
        this.db = request.result;

        const store: IDBObjectStore = this.db.createObjectStore("accounts", { keyPath: "id" });
        store.createIndex("token", "token", { unique: false });
      };
    });
  }

  private async reqStore(
    mode: "readonly" | "readwrite",
    callback: (store: IDBObjectStore | undefined) => void
  ): Promise<void> {
    const transaction: IDBTransaction | undefined = this.db?.transaction("accounts", mode);
    const store: IDBObjectStore | undefined = transaction?.objectStore("accounts");

    callback(store);
  }

  private async handleRequest<T>(
    request: IDBRequest<T> | undefined,
    resolve: (value: T) => void,
    reject: (reason?: any) => void
  ): Promise<void> {
    if (!request) {
      reject(new Error("request is null"));
      return;
    }

    request.onsuccess = (): void => {
      resolve(request.result);
    };

    request.onerror = (): void => {
      reject(request.error);
    };
  }

  /**
   * @returns {Promise<DBToken[]>}
   */
  public async getAccounts(): Promise<DBToken[]> {
    return new Promise((resolve, reject): void => {
      this.reqStore("readonly", (store: IDBObjectStore | undefined): void => {
        const request: IDBRequest<DBToken[]> | undefined = store?.getAll();
        this.handleRequest(request, resolve, reject);
      });
    });
  }

  /**
   * @param {number} id
   * @returns {Promise<DBToken | undefined>}
   */
  public async getAccount(id: number): Promise<DBToken | undefined> {
    return new Promise((resolve, reject): void => {
      this.reqStore("readonly", (store: IDBObjectStore | undefined): void => {
        const request: IDBRequest<DBToken | undefined> | undefined = store?.get(id);
        this.handleRequest(request, resolve, reject);
      });
    });
  }

  /**
   * @param {number} id
   * @param {string} token
   * @returns {Promise<IDBValidKey | undefined>}
   */
  public async addAccount(id: number, token: string): Promise<IDBValidKey | undefined> {
    const find = await this.getAccount(id);
    if (find) return this.updateAccount(id, token);

    return new Promise((resolve, reject): void => {
      this.reqStore("readwrite", (store: IDBObjectStore | undefined): void => {
        const request: IDBRequest<IDBValidKey> | undefined = store?.add({ id, token });
        this.handleRequest(request, resolve, reject);
      });
    });
  }

  /**
   * @param {number} id
   * @param {string} token
   * @returns {Promise<IDBValidKey | undefined>}
   */
  private async updateAccount(id: number, token: string): Promise<IDBValidKey | undefined> {
    return new Promise((resolve, reject): void => {
      this.reqStore("readwrite", (store: IDBObjectStore | undefined): void => {
        const request: IDBRequest<IDBValidKey> | undefined = store?.put({ id, token });
        this.handleRequest(request, resolve, reject);
      });
    });
  }

  /**
   * @param {number} id
   * @returns {Promise<void>}
   */
  public async deleteAccount(id: number): Promise<void> {
    return new Promise((resolve, reject): void => {
      this.reqStore("readwrite", (store: IDBObjectStore | undefined): void => {
        const request: IDBRequest<undefined> | undefined = store?.delete(id);
        this.handleRequest(request, resolve, reject);
      });
    });
  }

  /**
   * @returns {Promise<void>}
   */
  public async deleteAllAccounts(): Promise<void> {
    return new Promise((resolve, reject): void => {
      this.reqStore("readwrite", (store: IDBObjectStore | undefined): void => {
        const request: IDBRequest<undefined> | undefined = store?.clear();
        this.handleRequest(request, resolve, reject);
      });
    });
  }
}
